<div class="api-background">
    <div class="grid-container">
        <mat-grid-list cols="{{ ( cardLayout | async )?.columns }}" rowHeight="125px">
            <mat-grid-tile [colspan]="( cardLayout | async )?.miniSelector!.cols"
                [rowspan]="( cardLayout | async )?.miniSelector!.rows">
                <mat-card class="dashboard-card mat-elevation-z9">
                    <button class = "release-notes-btn" mat-raised-button (click)="openReleaseNotes()">Release Notes</button>
                    <mat-card-header class="mini-card-header">
                        <mat-card-title>
                            <h1 class="value-text">Generate Request</h1>
                        </mat-card-title>
                        

                    </mat-card-header>
                    <div class="coord-picker-div">
                        <div class="coord-picker-left">
                            <div class="coord-picker-row">
                                <div class="toggle-div">
                                    <mat-button-toggle-group name="coordSelector" aria-label="Coordinates"
                                        value="manual" (change)="onValChange($event.value)" class="toggle-btn-group">
                                        <mat-button-toggle value="manual">Manual</mat-button-toggle>
                                        <mat-button-toggle value="map">Point</mat-button-toggle>
                                        <!-- <mat-button-toggle value="shape">Polygon</mat-button-toggle> -->
                                    </mat-button-toggle-group>
                                    <mat-form-field appearance="fill" class="time-period-select">
                                        <mat-label>Time Period</mat-label>
                                        <mat-select [(value)]="this.simpleApi.selectedTimePeriod">
                                            <mat-option [value]="timeP"
                                                *ngFor="let timeP of this.simpleApi.timePeriodList">
                                                {{
                                                timeP
                                                }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div class="header-icon-div">

                                        <mat-icon aria-hidden="false"
                                            [matTooltip]="this.simpleApi.getToolTipText('timePeriod')"
                                            matTooltipClass="example-tooltip"
                                            aria-label="Button that shows a red tooltip" class="response-icon-btn">info
                                        </mat-icon>
                                    </div>
                                    <mat-slide-toggle class="pdf-button" [(ngModel)]="simpleApi.generatePdfFlag">
                                        Create PDF
                                        <div class="img-loading-div"
                                            *ngIf="this.simpleApi.floodOccImgLoading || this.simpleApi.creatingPdf">
                                            <mat-spinner [diameter]="25"></mat-spinner>
                                        </div>
      
                                    </mat-slide-toggle>
                                    <div class="coord-picker-right">
                                        <div class="search-btn-div">
                                            <button class="account-button" mat-raised-button color="primary"
                                                (click)="this.simpleApi.startCalls()"> Search</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="coord-picker-row">
                                <span *ngIf="this.simpleApi.drawType !== 'rect'" class="draw-details-div">
                                    <mat-form-field class="cood-input-field" appearance="fill">
                                        <mat-label>Lat</mat-label>
                                        <input type="number" min="-90" max="90" matInput
                                            [(ngModel)]="this.simpleApi.inputLat">
                                    </mat-form-field>
                                    <mat-form-field class="cood-input-field" appearance="fill">
                                        <mat-label>Lon</mat-label>
                                        <input type="number" min="-180" max="180" matInput
                                            [(ngModel)]="this.simpleApi.inputLon">
                                    </mat-form-field>
                                    <mat-form-field appearance="fill" class="adj-cells-select">
                                        <mat-label>Adjacent Cells</mat-label>
                                        <mat-select [(value)]="this.simpleApi.selectedAdjCell" (change)="filterBuffer($event)">
                                            <mat-option [value]="number"
                                                *ngFor="let number of this.simpleApi.adjCellList">
                                                {{
                                                number
                                                }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div class="header-icon-div-adj">
                                        <mat-icon aria-hidden="false"
                                            [matTooltip]="this.simpleApi.getToolTipText('adjCells')"
                                            matTooltipClass="example-tooltip"
                                            aria-label="Button that shows a red tooltip" class="response-icon-btn">info
                                        </mat-icon>
                                    </div>
                                    <mat-form-field appearance="fill" class="adj-cells-select">
                                        <!-- <input type="number" min="0" max="24" matInput
                                            [(ngModel)]="this.simpleApi.selectedBufferCell"(selectionChange)="this.checkBufferCells($event)"> -->
                                        <mat-label>Buffer Cells</mat-label>
                                        <!-- <mat-select [(value)]="this.selectedBufferCell" (selectionChange)="this.checkBufferCells($event)" (change)="this.checkBufferCells($event)">
                                            <mat-option [value]="number"
                                                *ngFor="let number of this.simpleApi.bufferCellList"
                                                >
                                                {{
                                                number
                                                }}
                                            </mat-option>
                                        </mat-select> -->
                                        <mat-select [(ngModel)]="this.simpleApi.selectedBufferCell">
                                            <mat-option *ngFor="let b of bufferCellOptions" [value]="b">{{b}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div class="header-icon-div-adj">
                                        <mat-icon aria-hidden="false"
                                            [matTooltip]="this.simpleApi.getToolTipText('buffCells')"
                                            matTooltipClass="example-tooltip"
                                            aria-label="Button that shows a red tooltip" class="response-icon-btn">info
                                        </mat-icon>
                                    </div>

                                    <mat-form-field class="pdf-filename" appearance="fill"
                                        *ngIf="simpleApi.generatePdfFlag">
                                        <mat-label>File Name</mat-label>
                                        <input matInput placeholder="FileName" type="text"
                                            [(ngModel)]="simpleApi.pdfName">
                                    </mat-form-field>
                                </span>
                                <span *ngIf="this.simpleApi.drawType == 'rect'" class="draw-details-div">
                                    <mat-form-field class="cood-input-field" appearance="fill">
                                        <mat-label>Min Lat</mat-label>
                                        <input type="number" min="-90" max="90" matInput
                                            [(ngModel)]="this.simpleApi.minInputLat">
                                    </mat-form-field>
                                    <mat-form-field class="cood-input-field" appearance="fill">
                                        <mat-label>Min Lon</mat-label>
                                        <input type="number" min="-180" max="180" matInput
                                            [(ngModel)]="this.simpleApi.minInputLon">
                                    </mat-form-field>
                                    <mat-form-field class="cood-input-field" appearance="fill">
                                        <mat-label>Max Lat</mat-label>
                                        <input type="number" min="-90" max="90" matInput
                                            [(ngModel)]="this.simpleApi.maxInputLat">
                                    </mat-form-field>
                                    <mat-form-field class="cood-input-field" appearance="fill">
                                        <mat-label>Max Lon</mat-label>
                                        <input type="number" min="-180" max="180" matInput
                                            [(ngModel)]="this.simpleApi.maxInputLon">
                                    </mat-form-field>
                                </span>
                            </div>
                        </div>
                        <!-- <div class="coord-picker-right">
                            <div class="search-btn-div">
                                <button class="account-button" mat-raised-button color="primary"
                                    (click)="this.simpleApi.startCalls()"> Search</button>
                            </div>
                        </div> -->

                    </div>
                </mat-card>
                <!-- <app-drought-date-selector></app-drought-date-selector> -->
            </mat-grid-tile>

            <mat-grid-tile [colspan]="( cardLayout | async )?.map!.cols" [rowspan]="( cardLayout | async )?.map!.rows">
                <mat-card class="dashboard-card mat-elevation-z9">
                    <mat-card-header class="mini-card-header">
                        <mat-card-title>
                        </mat-card-title>
                    </mat-card-header>
                    <div>
                        <app-map></app-map>
                    </div>

                </mat-card>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="( cardLayout | async )?.stats!.cols"
                [rowspan]="( cardLayout | async )?.stats!.rows">
                <mat-card class="dashboard-card mat-elevation-z9">
                    <mat-card-header class="mini-card-header">
                        <mat-card-title>
                            <h1 class="value-text">Flood Occurrence</h1>
                        </mat-card-title>
                    </mat-card-header>
                    <div class="date-picker-div margin-auto" *ngIf="!this.simpleApi.floodOccResponse">
                        <div *ngIf="this.simpleApi.floodOccLoading == false && !this.simpleApi.floodOccResponse">
                            Please Select Coordinates
                        </div>
                        <div *ngIf="this.simpleApi.floodOccLoading == true && !this.simpleApi.floodOccResponse">
                            <mat-spinner></mat-spinner>

                        </div>
                    </div>
                    <div class="date-picker-div overflow" *ngIf="this.simpleApi.floodOccResponse">
                        <div *ngIf="this.simpleApi.floodOccLoading == false && this.simpleApi.floodOccResponse"
                            class="stats-table-div">

                            <table mat-table #table1 [dataSource]="this.simpleApi.responseDataSource">
                                <!-- key Column -->
                                <ng-container matColumnDef="keyCol">
                                    <td mat-cell class="key-col" *matCellDef="let element"> {{element.keyCol}} </td>
                                </ng-container>

                                <!-- val Column -->
                                <ng-container matColumnDef="valCol">
                                    <td mat-cell class="val-col" *matCellDef="let element"> {{element.valCol}}
                                        <div class="response-icon-div"
                                            *ngIf=this.simpleApi.occurrenceInfoList(element.keyCol)>

                                            <mat-icon aria-hidden="false"
                                                [matTooltip]="this.simpleApi.getToolTipText(element.keyCol)"
                                                matTooltipClass="example-tooltip"
                                                aria-label="Button that shows a red tooltip" class="response-icon-btn">
                                                info</mat-icon>
                                        </div>
                                    </td>

                                </ng-container>
                                <!-- info Column -->
                                <!-- <ng-container matColumnDef="infoCol">
                                    <td mat-cell *matCellDef="let element"> {{element.info}} </td>
                                </ng-container> -->

                                <tr mat-row *matRowDef="let row; columns: this.simpleApi.responseDisplayedColumns;">
                                </tr>
                            </table>

                        </div>

                    </div>
                </mat-card>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="( cardLayout | async )?.history!.cols"
                [rowspan]="( cardLayout | async )?.history!.rows">
                <mat-card class="dashboard-card mat-elevation-z9">
                    <mat-card-header class="mini-card-header">
                        <mat-card-title>
                            <h1 class="value-text">Flood History</h1>
                        </mat-card-title>
                    </mat-card-header>
                    <div class="date-picker-div margin-auto" *ngIf="!this.simpleApi.floodHistoryResponse">
                        <div
                            *ngIf="this.simpleApi.floodHistoryLoading == false && !this.simpleApi.floodHistoryResponse">
                            Please Select Coordinates
                        </div>
                        <div *ngIf="this.simpleApi.floodHistoryLoading == true && !this.simpleApi.floodHistoryResponse">
                            <mat-spinner></mat-spinner>
                        </div>
                    </div>
                    <div class="date-picker-div" *ngIf="this.simpleApi.floodHistoryResponse">
                        <div *ngIf="this.simpleApi.floodHistoryResponse" class="history-table-div">
                            Total Observed Events From: {{this.simpleApi.eventDateRange}} : <span class="length-span">
                                {{this.simpleApi.correctEventTotal}}</span>
                            <div class="history-table-conatiner">
                                <table mat-table [dataSource]="this.simpleApi.historyDataSource">
                                    <!-- Position Column -->
                                    <ng-container matColumnDef="date">
                                        <th mat-header-cell *matHeaderCellDef> Date (MM/YYYY) </th>
                                        <td mat-cell *matCellDef="let element"> {{element.date}} </td>
                                    </ng-container>

                                    <!-- Name Column -->
                                    <ng-container matColumnDef="classification">
                                        <th mat-header-cell *matHeaderCellDef>
                                            Confidence
                                            <mat-icon aria-hidden="false"
                                                [matTooltip]="this.simpleApi.getToolTipText('classification')"
                                                matTooltipClass="example-tooltip"
                                                aria-label="Button that shows a red tooltip"
                                                class="confidence-info response-icon-btn">
                                                info
                                            </mat-icon>

                                        </th>
                                        <td mat-cell *matCellDef="let element"
                                            [ngStyle]="this.simpleApi.getClassificationStyle(element.classification)">
                                            {{element.classification}} </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="this.simpleApi.displayedColumns; sticky: true">
                                    </tr>
                                    <tr mat-row *matRowDef="let row; columns: this.simpleApi.displayedColumns;"></tr>
                                </table>
                            </div>

                            <!-- <div *ngFor="let event of this.simpleApi.historyEvents | keyvalue">
                                Event {{event.key}} Intensity = {{event.value}}
                            </div> -->

                        </div>
                    </div>
                </mat-card>
            </mat-grid-tile>

        </mat-grid-list>
    </div>
</div>
<span class="version-span">{{environment.version}}</span>