import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { SimpleApiService } from 'src/app/services/simple-api.service';
import { environment } from 'src/environments/environment';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-simple-api',
  templateUrl: './simple-api.component.html',
  styleUrls: ['./simple-api.component.scss']
})
export class SimpleApiComponent implements OnInit {
  environment = environment;
  //bufferCellOptions = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25]
  selectedBufferCell;
  cardLayout = this.breakpointObserver.observe([
    Breakpoints.Handset,
    Breakpoints.XSmall,
    Breakpoints.Small,
    Breakpoints.Medium,
    Breakpoints.Large
  ]
  ).pipe(
    map((state: BreakpointState) => {
      if (state.breakpoints[Breakpoints.Handset] || state.breakpoints[Breakpoints.XSmall] ) {
        return {
          columns: 8,
          miniSelector: { cols: 8, rows: 3 },
          stats: { cols: 8, rows: 5 },
          history: { cols: 8, rows: 5 },
          map: { cols: 8, rows: 7 },
          // columns: 8,
          // miniSelector: { cols: 6, rows: 2 },
          // stats: { cols: 3, rows: 4 },
          // history: { cols: 3, rows: 4 },
          // map: { cols: 4, rows: 6 },
        };
      }else if (state.breakpoints[Breakpoints.Small]) {
        return {
          columns: 8,
          miniSelector: { cols: 8, rows: 3 },
          stats: { cols: 8, rows: 4 },
          history: { cols: 8, rows: 4 },
          map: { cols: 8, rows: 6 },
          // columns: 8,
          // miniSelector: { cols: 6, rows: 2 },
          // stats: { cols: 3, rows: 4 },
          // history: { cols: 3, rows: 4 },
          // map: { cols: 4, rows: 6 },
        };
      }
      else if (state.breakpoints[Breakpoints.Medium]) {
        return {
          columns: 8,
          miniSelector: { cols: 8, rows: 2 },
          stats: { cols: 3, rows: 3 },
          history: { cols:3, rows: 3 },
          map: { cols: 5, rows: 6 },
        };
      }
      else if (state.breakpoints[Breakpoints.Large]) {
        return {
          columns: 11,
          miniSelector: { cols: 6, rows: 2 },
          stats: { cols: 3, rows: 4 },
          history: { cols: 3, rows: 4 },
          map: { cols: 5, rows: 6 },
        };
      }else{
        return {

          columns: 9,
          miniSelector: { cols: 4, rows: 2 },
          stats: { cols: 2, rows: 5 },
          history: { cols: 2, rows: 5 },
          map: { cols: 5, rows: 7 },
        };
      }

    },error =>{
      console.log(error)
    })
  );

  constructor(private breakpointObserver: BreakpointObserver, public simpleApi: SimpleApiService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.selectedBufferCell = 0;
  }

  
  openReleaseNotes() {
    const dialogRef = this.dialog.open(ReleaseNotesDialog);

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log(`Dialog result: ${result}`);
    // });
  }

  onValChange(val){
    if(val == "map"){
      
      this.simpleApi.drawType = "point";
      this.simpleApi.setDrawStart(true);
    }else if(val == "shape"){
      //draw shape
      this.simpleApi.drawType = "rect";
      this.simpleApi.setDrawStart(true);
    }else{
      this.simpleApi.drawType = "";
      this.simpleApi.setDrawStart(false);
    }
  }

  // checkBufferCells(evt){
  //   console.log(typeof(this.simpleApi.selectedBufferCell))
  //   console.log(evt);
  //   console.log(this.simpleApi.selectedBufferCell);
  //   console.log(this.simpleApi.selectedAdjCell);
  //   this.simpleApi.selectedBufferCell = evt.value
  //   if(this.simpleApi.selectedBufferCell >= this.simpleApi.selectedAdjCell){
  //     console.log("in if");
  //     let newVal = this.simpleApi.selectedAdjCell -1
  //     this.simpleApi.selectedBufferCell =  newVal;
  //     console.log(this.simpleApi.selectedBufferCell);
  //     console.log(typeof(this.simpleApi.selectedBufferCell))
  //     this.selectedBufferCell = newVal;

  //   }
  // }

  filterBuffer(evt){
    //console.log(evt);

  }

  get bufferCellOptions(){
    let arr = []
    for(var i=0; i<=this.simpleApi.selectedAdjCell; i++){
      arr.push(i);
    }
    return arr;
  }

  

}
@Component({
  selector: 'releaes-notes-dialog',
  templateUrl: 'release-notes-dialog.html',
  styleUrls: ['./simple-api.component.scss']
})
export class ReleaseNotesDialog {}

// const ELEMENT_DATA: HistoryEvent[] = [
// ];