<!-- <nav class="navbar navbar-light bg-light static-top fixed-top">
    <div class="container">
        <a class="navbar-brand" href="#"><img src="/assets/logos/transparent_long.png" height="40px"> </a>
        <a *ngIf="this.loggedIn" class="btn btn-primary btn-teal" (click)="this.auth.logOut()">Sign Out</a>
    </div>
</nav> -->

<mat-toolbar fxLayout="row" color="accent">
    <span fxFlex><a class="navbar-brand" href="#"><img src="/assets/logos/transparent_long.png" height="40px">
        </a></span>

    <button mat-button [ngClass]="{'active-tab': item.routerLink=== this.router.url}" *ngFor="let item of menuItems"
        [routerLink]="item.routerLink" [fxShow]="item.showOnDesktop  && this.loggedIn"
        [fxShow.xs]="item.showOnMobile  && this.loggedIn" [fxShow.sm]="item.showOnTablet && this.loggedIn">
        <mat-icon class="mr">{{item.icon}}</mat-icon>
        {{item.label}}
    </button>
    <ng-container>
        <button mat-button [matMenuTriggerFor]="apiMenu" [fxShow]="this.loggedIn" [fxShow.sm]="this.loggedIn"
            [fxShow.xs]="false" [ngClass]="{'active-tab': this.router.url.includes
        ('api')}">
            <mat-icon class="mr">code</mat-icon>
            API
        </button>
        <mat-menu #apiMenu="matMenu">
            <ng-container>
                <div [fxShow]="this.loggedIn" [fxShow.sm]="this.loggedIn" [fxShow.xs]="this.loggedIn"
                    [routerLink]="['api']">
                    <button mat-menu-item>
                        <mat-icon class="mr">description</mat-icon>
                        API Doc
                    </button>
                    <mat-divider></mat-divider>
                </div>
                <div [fxShow]="this.auth.apiAccess" [fxShow.sm]="this.auth.apiAccess" [fxShow.xs]="this.auth.apiAccess"
                    [routerLink]="['api','keys']">
                    <button mat-menu-item>
                        <mat-icon class="mr">vpn_key</mat-icon>
                        API Keys
                    </button>
                    <mat-divider></mat-divider>
                </div>
                <div [fxShow]="this.auth.simpleApiAccess" [fxShow.sm]="this.auth.simpleApiAccess"
                    [fxShow.xs]="this.auth.simpleApiAccess" [routerLink]="['api','rt']">
                    <button mat-menu-item>
                        <mat-icon class="mr">api</mat-icon>
                        reThought API
                    </button>

                    <mat-divider></mat-divider>
                </div>
                <div [fxShow]="this.auth.internalAccess " [fxShow.sm]="this.auth.internalAccess "
                [fxShow.xs]="this.auth.internalAccess " [routerLink]="['api','logs']">
                    <button mat-menu-item>
                        <mat-icon class="mr">api</mat-icon>
                        Internal Logs
                    </button>

                    <mat-divider></mat-divider>
                </div>
                <!-- <div [fxShow]="this.auth.simpleApiAccess" [fxShow.sm]="this.auth.simpleApiAccess"
                    [fxShow.xs]="this.auth.simpleApiAccess" [routerLink]="['api','releasenotes']">
                    <button mat-menu-item>
                        <mat-icon class="mr">format_list_bulleted</mat-icon>
                        Release Notes
                    </button>
                </div> -->

            </ng-container>
        </mat-menu>
    </ng-container>


    <ng-container>
        <button mat-icon-button [matMenuTriggerFor]="dropMenu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #dropMenu="matMenu">
            <ng-container *ngFor="let item of menuItems">
                <div [fxShow]="!item.showOnDesktop && this.loggedIn" [fxShow.sm]="!item.showOnTablet && this.loggedIn"
                    [fxShow.xs]="!item.showOnMobile && this.loggedIn"
                    [ngClass]="{'active-tab': item.routerLink=== this.router.url}" [routerLink]="item.routerLink">
                    <button mat-menu-item>
                        <mat-icon class="mr">{{item.icon}}</mat-icon>
                        {{item.label}}
                    </button>
                    <mat-divider></mat-divider>
                </div>
            </ng-container>
            <!--api stuff-->

            <ng-container>
                <div [fxShow]="false" [fxShow.sm]="false" [fxShow.xs]="this.loggedIn" [routerLink]="['api']">
                    <button mat-menu-item>
                        <mat-icon class="mr">description</mat-icon>
                        API Doc
                    </button>
                    <mat-divider></mat-divider>
                </div>
                <div [fxShow]="false" [fxShow.sm]="false" [fxShow.xs]="this.auth.apiAccess"
                    [routerLink]="['api','keys']">
                    <button mat-menu-item>
                        <mat-icon class="mr">vpn_key</mat-icon>
                        API Keys
                    </button>
                    <mat-divider></mat-divider>
                </div>
                <div [fxShow]="false" [fxShow.sm]="false" [fxShow.xs]="this.auth.simpleApiAccess"
                    [routerLink]="['api','rt']">
                    <button mat-menu-item>
                        <mat-icon class="mr">api</mat-icon>
                        reThought API
                    </button>
                </div>
                <!-- <mat-divider></mat-divider>
                <div [fxShow]="false" [fxShow.sm]="false" [fxShow.xs]="this.auth.simpleApiAccess"
                    [routerLink]="['api','releasenotes']">
                    <button mat-menu-item>
                        <mat-icon class="mr">format_list_bulleted</mat-icon>
                        Release Notes
                    </button>
                </div> -->
            </ng-container>

            <!--end api-->
            <ng-container>
                <div [fxShow]="true" [fxShow.sm]="true" [fxShow.xs]="true">
                    <button mat-menu-item (click)="this.auth.homepage()">
                        <mat-icon class="mr">home</mat-icon>
                        Hydronos Labs Homepage
                    </button>
                    <mat-divider></mat-divider>
                </div>
                <div [fxShow]="this.loggedIn" [fxShow.sm]="this.loggedIn" [fxShow.xs]="this.loggedIn">
                    <button mat-menu-item (click)="this.auth.logOut()">
                        <mat-icon class="mr">logout</mat-icon>
                        Logout
                    </button>
                    <mat-divider></mat-divider>
                </div>
            </ng-container>
        </mat-menu>
    </ng-container>
</mat-toolbar>