import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApiDocComponent } from './api-doc/api-doc.component';
import { ApiKeysComponent } from './api-keys/api-keys.component';
import { ReleaseNotesComponent } from './release-notes/release-notes.component';
import { SimpleApiComponent } from './simple-api/simple-api.component';
import { LogsComponent } from './logs/logs.component';

const routes: Routes = [
  {
    path:'',
    component: ApiDocComponent 
  },
  {
    path:'keys',
    component: ApiKeysComponent 
  },
  {
    path:'rt',
    component: SimpleApiComponent 
  },
  {
    path:'releasenotes',
    component: ReleaseNotesComponent 
  },
  {
    path:'logs',
    component: LogsComponent 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ApiRoutingModule { }
