import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ApiRoutingModule } from './api-routing.module';
import { ApiDocComponent } from './api-doc/api-doc.component';
import { ApiKeysComponent } from './api-keys/api-keys.component';

import { MatGridListModule } from '@angular/material/grid-list'
import { MatCardModule } from '@angular/material/card'
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import {MatTableModule} from '@angular/material/table';
import {CdkTableModule} from '@angular/cdk/table';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import { ReleaseNotesDialog, SimpleApiComponent } from './simple-api/simple-api.component';
import {MatSelectModule} from '@angular/material/select';
import { MapComponent } from './map/map.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatExpansionModule} from '@angular/material/expansion';

import { ReleaseNotesComponent } from './release-notes/release-notes.component';
import {MatDialogModule} from '@angular/material/dialog';
import { LogsComponent } from './logs/logs.component';


@NgModule({
  declarations: [ApiDocComponent, ApiKeysComponent, SimpleApiComponent, MapComponent, ReleaseNotesComponent, ReleaseNotesDialog, LogsComponent],
  imports: [
    CommonModule,
    ApiRoutingModule,
    MatCardModule,
    MatGridListModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatTableModule,
    CdkTableModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatExpansionModule
  ]
})
export class ApiModule { }
