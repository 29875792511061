import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import 'ol/ol.css';
import Map from 'ol/Map';
import { ScaleLine, defaults as defaultControls } from 'ol/control';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import { Stamen } from 'ol/source';
import { View } from 'ol';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root'
})

export class SimpleApiService {
  
  //Used for input variables from user
  inputLat = 39.96114;
  inputLon = -74.07202;
  minInputLon;
  minInputLat;
  maxInputLon;
  maxInputLat;

  floodOccLoading = false;
  floodOccResponse = null;
  floodOccImgLoading = false;
  floodOccImgResponse = null;
  floodHistoryLoading = false;
  floodHistoryResponse = null;

  //Used for filling in tables
  baseStats;
  adjStats;

  historyStats;
  historyEvents;

  floodOccImgUrl;
  floodOccImgStaticUrl;
  colorbarUrl;

  //Pre selected inputs for adjacent cells
  selectedAdjCell = 20;
  adjCellList = [1, 2, 3, 4, 5, 10, 15, 20, 25];
  selectedBufferCell = 0;
  bufferCellList = [0,1, 2, 3, 4, 5,6,7,8,9, 10,11,12,13,14, 15,16,17,18,19,20,21,22,23,24]

  selectedTimePeriod = "All";
  timePeriodList = ["All", "Pre", "Post"]
  imageTypeList = ["simple", "html"]
  selectedImageType = "simple";

  /**
   * API Url here
   */
  apiUrl = environment.apiUrl;
  updateMapImage = new Subject<Boolean>();
  centerMapImage = new Subject<Boolean>();
  drawStart = new Subject<Boolean>();
  drawType = "";
  responseDisplayedColumns = ['keyCol', 'valCol'];
  responseDataSource: ResponseInterface[];

  displayedColumns = ['date', 'classification'];
  historyDataSource: HistoryEvent[];

  infoListVals = ['Flood Occurrence Score (Point)', 'Flood Occurrence Score (Region)', 'Clear Sky Obs. (Point)']
  apiKey = environment.hydronosKey;
  wktShape: any;
  generatePdfFlag: boolean;
  creatingPdf: boolean = false;
  singlePdfFlag: boolean = false; //used to only create one pdf call
  pdfName: any;
  footNotesNum;

  correctEventTotal;//holds the correct total events for the given time period
  eventDateRange;//holds the date range


  constructor(private http: HttpClient, private sanitizer: DomSanitizer, private auth: AuthService, public dialogService: DialogService) {
    


    this.footNotesNum = {
      "Adjacent Cells": "*",
      "Flood Occurrence Score (Point)": "***",
      "Flood Occurrence Score (Region)": "****",
      "Clear Sky Obs. (Point)": "*****",
      "Confidence": "**",
    }
  }
  map: any;

  //Determine which calls to make, shape calls in future release, not build yet
  startCalls() {
    //add in temp hack to send different api keys
    //if user is rt test user change api key
    if (this.auth.user.uid == environment.rtUserId) {
      this.apiKey = environment.rtKey;
    }
    if (!this.validateInput()) {
      //ask for correct input
    } else {
      //do things
      if (this.drawType == "rect") {
        this.shapeCalls();
      } else {
        this.pointCalls();
      }
    }
  }

  /**
   * Calls that only have lat/lon inputs
   */
  pointCalls() {
    console.log("starting point calls")
    this.singlePdfFlag = false;
    this.setCenterMapImage(true);
    console.time("occ");
    console.time("hist");
    console.time("img")
    console.time("static")

    this.floodHistory().subscribe((data: any) => {
      this.floodHistoryResponse = data;
      this.historyStats = data.result[0]
      this.historyEvents = [];
      Object.entries(this.historyStats.events).forEach(([key, value]) => {
        //checks if date matches with current selected time period, assumes that the time string is already within 1984 to 2019
        var valid = this.checkEventValid(key);
        if(valid){
          var formated = this.formatDate(key);
          var tmp: HistoryEvent = { date: formated, classification: value.toString() }
          this.historyEvents.push(tmp)
        }
      });
      this.historyEvents = this.historyEvents.reverse();
      this.historyDataSource = this.historyEvents;
      this.floodHistoryLoading = false;
      this.correctEventTotal = this.getCorrectHistoryEvents();
      this.eventDateRange = this.getEventDateRange();//holds the date range
      console.timeEnd("hist");
      this.generatePdfCall();
    });
    this.floodOccurrenceImg().subscribe((data: any) => {
      console.timeEnd("img")
      this.floodOccImgResponse = data;
      this.floodOccImgLoading = false;
      if (this.selectedImageType == "html") {
        this.floodOccImgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(data));
      }
      else {
        //If we don't have a color bar, find onee
        if (!this.colorbarUrl) {
          this.getColorBar(data.result[0].colorbarPath)
        }
        this.callGetImage(data.result[0].imagePath);
      }
    }, (error: any) => {
      console.log(error);
      console.log(error.message)
      this.floodOccLoading = false;
      this.floodHistoryLoading = false;
      this.floodOccImgLoading = false;
      this.creatingPdf = false;

      //launch popup error?
        const errOptions = {
          title: 'Error',
          message: 'There was an issue with the API',
          errorMessage: "",
          confirmText: 'Close'
        };
        this.dialogService.open(errOptions);
    });

    //Determine if we need to generate the pdf, then get the static image
    if(this.generatePdfFlag){
      console.log("in generate pdf")
      this.creatingPdf = true;
      
      this.floodOccurrenceImgStatic().subscribe((data: any) => {
        console.timeEnd("static")
        if (this.selectedImageType == "html") {
          this.floodOccImgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(data));
        }
        else {
          this.callGetImageStatic(data.result[0].imagePath);
        }
      }, (error: any) => {
        console.log(error);
        console.log(error.message)
        this.floodOccLoading = false;
        this.floodOccImgLoading = false;
        this.floodHistoryLoading = false;
        this.creatingPdf = false
        //launch popup error?
          const errOptions = {
            title: 'Error',
            message: 'There was an issue with the API',
            errorMessage: "",
            confirmText: 'Close'
          };
          this.dialogService.open(errOptions);
      });
    }


    this.floodOccurrence().subscribe((data: any) => {
      console.timeEnd("occ");
      this.floodOccResponse = data;
      console.log(data);
      this.floodOccLoading = false;
      this.baseStats = data.result[0];
      this.adjStats = data.result[1]
      this.responseDataSource = [
        { keyCol: "Flood Occurrence Score (Point)", valCol: this.baseStats.value.toFixed(5) },
        { keyCol: "Flood Occurrence Score (Region)", valCol: this.adjStats.mean.toFixed(5) },
        { keyCol: "Clear Sky Obs. (Point)", valCol: this.baseStats.nObs },
        { keyCol: "Min (Region)", valCol: this.adjStats.min.toFixed(5) },
        { keyCol: "Max (Region)", valCol: this.adjStats.max.toFixed(5) },
        { keyCol: "Median (Region)", valCol: this.adjStats.median.toFixed(5) },
        { keyCol: "Std Dev (Region)", valCol: this.adjStats.std.toFixed(5) },
        { keyCol: "Source", valCol: this.baseStats.source },
        { keyCol: "Data Version", valCol: this.baseStats.dataVersion },
      ];
      this.generatePdfCall();
    });
  }

  generatePdfCall() {
    if (this.generatePdfFlag) {
      if (!this.floodHistoryLoading && !this.floodOccImgLoading && !this.floodOccLoading && this.floodOccImgStaticUrl && !this.singlePdfFlag) {
        this.singlePdfFlag = true;
        this.createPdf();
      }
    }
  }

  /**
   * Functions not build yet for shape inputs instead of just lat lon
   */
  shapeCalls() {
    alert("Function not build yet")
    // this.setCenterMapImage(true);
    // this.floodOccurrence().subscribe((data: any) => {
    //   this.floodOccResponse = data;
    //   this.floodOccLoading = false
    //   //data.result[0] = base stats
    //   //data.result[1] = adjacent stats
    //   this.baseStats = data.result[0];
    //   this.adjStats = data.result[1]
    //   this.responseDataSource = [
    //     { keyCol: "Flood Occurrence Score (Point)", valCol: this.baseStats.value.toFixed(4) },
    //     { keyCol: "Flood Occurrence Score (Region)", valCol: this.adjStats.mean.toFixed(4) },
    //     { keyCol: "Clear Sky Obs. (Point)", valCol: this.baseStats.nObs },
    //     { keyCol: "Min (Region)", valCol: this.adjStats.min.toFixed(4) },
    //     { keyCol: "Max (Region)", valCol: this.adjStats.max.toFixed(4) },
    //     { keyCol: "Median (Region)", valCol: this.adjStats.median.toFixed(4) },
    //     { keyCol: "Std Dev (Region)", valCol: this.adjStats.std.toFixed(4) },
    //     { keyCol: "Source", valCol: this.baseStats.source },
    //     { keyCol: "Data Version", valCol: this.baseStats.dataVersion },
    //   ]
    // });

    // this.floodOccurrenceImg().subscribe((data: any) => {
    //   //(if simple) need to get meta data and then call api again for image url?
    //   this.floodOccImgResponse = data;
    //   this.floodOccImgLoading = false
    //   if (this.selectedImageType == "html") {
    //     this.floodOccImgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(data));
    //   }
    //   else {
    //     // const urlCreator = window.URL;
    //     // //this.floodOccImgUrl = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(data))
    //     // this.floodOccImgUrl = urlCreator.createObjectURL(data);
    //     // console.log(this.floodOccImgUrl);
    //     // this.setUpdateMapImage(true);
    //     if (!this.colorbarUrl) {
    //       this.getColorBar(data.result[0].colorbarPath)
    //     }
    //     this.callGetImage(data.result[0].imagePath);
    //   }



    // });
    // this.floodHistory().subscribe((data: any) => {
    //   this.floodHistoryResponse = data;
    //   this.historyStats = data.result[0]
    //   this.historyEvents = [];
    //   Object.entries(this.historyStats.events).forEach(([key, value]) => {
    //     var formated = this.formatDate(key);
    //     var tmp: HistoryEvent = { date: formated, classification: value.toString() }
    //     this.historyEvents.push(tmp)
    //   });
    //   this.historyDataSource = this.historyEvents;
    //   this.floodHistoryLoading = false
    // });
  }

  validateInput() {
    if (this.drawType == "rect") {
      if (!this.wktShape) {
        alert("Please draw a shape on the map")
      } else {
        return true
      }
    } else {
      if (!this.inputLat || !this.inputLon) {
        alert("Please enter a Lat and a Lon");
        return false;
      }
      else if (this.inputLat < -90 || this.inputLat > 90) {
        alert("Lat must be between -90 and 90");
        return false;
      }
      else if (this.inputLon < -180 || this.inputLon > 180) {
        alert("Lon must be between -180 and 180");
        return false;
      }
      else {
        return true;
      }
    }


  }

  public floodOccurrence() {
    this.floodOccResponse = null;
    this.floodOccLoading = true;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': this.apiKey
    });
    let params = new HttpParams().set("lat", this.inputLat.toString()).set("lon", this.inputLon.toString()).set("adjacentCells", this.selectedAdjCell.toString()).set("timePeriod", this.selectedTimePeriod.toLowerCase()).set("bufferCells",this.selectedBufferCell.toString()); //Create new HttpParams
    return this.http.get(this.apiUrl + "/flood/occurrence", { headers: headers, params: params });
  }

  public floodHistory() {
    this.floodHistoryResponse = null;
    this.floodHistoryLoading = true;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': this.apiKey
    });
    let params = new HttpParams().set("lat", this.inputLat.toString()).set("lon", this.inputLon.toString()).set("year", "1984"); //Create new HttpParams
    return this.http.get(this.apiUrl + "/flood/history", { headers: headers, params: params });
  }

  public floodOccurrenceImg() {
    this.floodOccImgResponse = null;
    this.floodOccImgLoading = true;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': this.apiKey
    });
    let params = new HttpParams().set("lat", this.inputLat.toString()).set("lon", this.inputLon.toString()).set("adjacentCells", this.selectedAdjCell.toString()).set("timePeriod", this.selectedTimePeriod.toLowerCase()).set("type", this.selectedImageType).set("bufferCells",this.selectedBufferCell.toString()); //Create new HttpParams
    return this.http.get(this.apiUrl + "/flood/generate_image", { headers: headers, params: params });//, responseType: "blob" });
  }
  public floodOccurrenceImgStatic() {
    this.floodOccImgResponse = null;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': this.apiKey
    });
    let params = new HttpParams().set("lat", this.inputLat.toString()).set("lon", this.inputLon.toString()).set("adjacentCells", this.selectedAdjCell.toString()).set("timePeriod", this.selectedTimePeriod.toLowerCase()).set("type", "static").set("bufferCells",this.selectedBufferCell.toString()); //Create new HttpParams
    return this.http.get(this.apiUrl + "/flood/generate_image", { headers: headers, params: params });//, responseType: "blob" });
  }

  //takes Url from api response and gets the actual image
  callGetImage(filename) {
    this.getImage(filename).subscribe((data) => {
      const urlCreator = window.URL;
      this.floodOccImgUrl = urlCreator.createObjectURL(data);
      this.setUpdateMapImage(true);
    },(error: any) => {
      console.log(error);
      console.log(error.message)
      //launch popup error?
        const errOptions = {
          title: 'Error',
          message: 'There was an error getting the map image from the API',
          errorMessage: "",
          confirmText: 'Close'
        };
        this.dialogService.open(errOptions);
        this.floodOccImgLoading = false;
    });
  }
  callGetImageStatic(filename) {
    var self = this
    this.getImage(filename).subscribe((data) => {
      const urlCreator = window.URL;
      //this.floodOccImgUrl = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(data))
      this.floodOccImgStaticUrl = urlCreator.createObjectURL(data);
      //this.setUpdateMapImage(true);
      self.generatePdfCall();
    },(error: any) => {
      console.log(error);
      console.log(error.message)
      //launch popup error?
        const errOptions = {
          title: 'Error',
          message: 'There was an error generating the pdf, could not get the image from the API',
          errorMessage: "",
          confirmText: 'Close'
        };
        this.dialogService.open(errOptions);
        this.creatingPdf = false
    });
  }

  getColorBar(url) {
    this.getImage(url).subscribe((data) => {
      const urlCreator = window.URL;
      this.colorbarUrl = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(data))
      //this.colorbarUrl = urlCreator.createObjectURL(data);
      //this.setUpdateMapImage(true);
    })
  }

  //Get the image from the api
  public getImage(filename) {
    const headers = new HttpHeaders({
      'responseType': 'blob',
      'x-api-key': this.apiKey
    });
    let params = new HttpParams().set("filename", filename);
    return this.http.get(this.apiUrl + "/flood/download_image", { headers: headers, params: params, responseType: "blob" });
  }

  getUpdateMapImage() {
    return this.updateMapImage;
  }

  setUpdateMapImage(flag) {
    this.updateMapImage.next(flag);
  }

  getCenterMapImage() {
    return this.centerMapImage;
  }

  setCenterMapImage(flag) {
    this.centerMapImage.next(flag);
  }
  getDrawStart() {
    return this.drawStart;
  }

  setDrawStart(flag) {
    this.drawStart.next(flag);
  }

  formatDate(string) {
    //YYYYMMDD
    var year = string.slice(0, 4);
    var month = string.slice(4, 6);
    var formated = month + "/" + year;
    return formated
  }

  occurrenceInfoList(type) {
    return this.infoListVals.includes(type)
  }

  getToolTipText(type) {
    switch (type) {
      case 'Flood Occurrence Score (Point)':
        return "This score represents the ratio of the number of water observations over the period of record normalized by the number of clear sky observations."
      case "Clear Sky Obs. (Point)":
        return "The count of satellite scenes containing the selected location with less than 20% cloud cover.";
      case "classification":
        return "A qualitative measure of the water classification confidence based on the satellite retrieval. Values can be: “High”, “Moderate” or “Low - Wetland”. The latter indicates a low confidence for surface inundation and/or presence of a wetland where vegetation is impacting the signal."
      case "adjCells":
        return "Number of adjacent 30m grid cells to calculate statistics over. For example, a value of 2 will yield a final 150m x 150m region.";
      case "buffCells":
        return "Number of 30m grid cells to exclude from the regional calculations. A value of 0 will not exclude any cells, a value of 1 excludes the center most cell, and values of 2+ exclude 2+ cells radially outwards from the center cell. Note: The value must be less than or equal to the value of adjacentCells."
      case "timePeriod":
        return "Range in years for calculating scores. All: 1984-2019, Pre: 1984-2000, Post: 2000-2019";
      case "Flood Occurrence Score (Region)":
        return "This score represents the average ratio of the number of water observations over the period of record normalized by the number of clear sky observations for the entire selected region."
    }
  }

  // getWktTooltip() {
  //   return this.wktShape;
  // }

  getClassificationStyle(type): object {
    switch (type) {
      case 'High':
        return { 'color': 'red' }
      case 'Moderate':
        return { 'color': 'orange' }
      default:
        return { 'color': 'yellow' }
    }
  }
  
  getEventDateRange(){
    if(this.selectedTimePeriod == "All"){
      return "1984-2019"
    }if(this.selectedTimePeriod == "Pre"){
      return "1984-2000"
    }else{
      return "2000-2019"
    }
  }
  //return the number of events within the correct time period
  getCorrectHistoryEvents(){
    return this.historyEvents.length
  }

  checkEventValid(dateString:string){
    var year = parseInt(dateString.slice(0, 4));

    if(this.selectedTimePeriod == "Pre"){
      if(year>=1984 && year <=2000){
        return true;
      }else{
        return false;
      }
    }else if (this.selectedTimePeriod == "Post"){
      if(year>=2000 && year <=2019){
        return true;
      }else{
        return false;
      }
    }else{
      return true;
    }
  }



  createPdf() {
    //create doc
    const doc = new jsPDF('p', 'mm', 'a4', true);
    //generate header and what now;
    this.createHeader(doc)
    this.getLocation(doc);
    this.getMapImage(doc);
    this.getFloodHistory(doc);
    this.getFloodOccurrence(doc);
    this.getFooter(doc);

    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);

    //If no file name is given, just use generic
    if (this.generatePdfFlag && this.pdfName) {
      doc.save(this.pdfName + "_" +today.toLocaleDateString() + ".pdf");
    } else {
      doc.save("flood_summary_" + today.toLocaleDateString() + ".pdf");
    }
    this.creatingPdf = false
  }

  createHeader(doc: jsPDF) {
    doc.addImage("assets/logos/transparent_long.png", "PNG", 5, 5, 80, 15, undefined, 'FAST');
    //contact info
    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.textWithLink("hydronoslabs.com", 170, 10, { url: "https://hydronoslabs.com" })
    doc.text("info@hydronoslabs.com", 170, 15)
    doc.text("+1(609)-681-6868", 170, 20)
    doc.line(0, 25, 210, 25); // horizontal line    
  }

  getLocation(doc: jsPDF) {
    //if not rect, it's just a point
    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    if (this.pdfName) {
      doc.text("Remotely Sensed Flood Occurrence Summary for " + this.pdfName, 10, 32)
    } else {
      doc.text("Remotely Sensed Flood Occurrence Summary", 10, 32)
    }

    let locString
    if (this.drawType != "rect") {
      locString = "Lat: " + this.inputLat + " Lon: " + this.inputLon;

      doc.setFont("helvetica", "normal");
      doc.setFontSize(12);
      doc.setTextColor("black")
      doc.text("Lat: ", 20, 38)

      doc.setTextColor(25, 182, 190)
      doc.text(this.inputLat.toString(), 30, 38)
      doc.setTextColor('black')
      doc.text("Lon: ", 50, 38)

      doc.setTextColor(25, 182, 190)
      doc.text(this.inputLon.toString(), 60, 38)
      doc.setTextColor('black')


    } else {
      locString = "Min Lat: " + this.minInputLat + " Min Lon: " + this.minInputLon + " Max Lat: " + this.maxInputLat + " Max Lon: " + this.maxInputLon;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(12);
      doc.text(locString, 20, 38)
    }


    //only add if adj cells?
    if (this.selectedAdjCell) {
      let adjString = "Adjacent Cells*: "
      doc.text(adjString, 20, 43);
      doc.setTextColor(25, 182, 190)
      doc.text(this.selectedAdjCell.toString(), 55, 43)
      doc.setTextColor('black')

    }

    let tmp = this.getEventDateRange()
    let tpString = "Time Period: ";
    doc.text(tpString, 20, 48);
    doc.setTextColor(25, 182, 190)
    doc.text(tmp, 50, 48)
    doc.setTextColor('black')

  }

  getFloodOccurrence(doc: jsPDF) {

    let tbody = [];
    this.responseDataSource.forEach((data: any) => {
      let fn = this.getFootNotesNum(data.keyCol);
      var keyColVal = data.keyCol
      if (fn) {
        keyColVal = keyColVal + " " + fn
      }
      tbody.push([keyColVal, data.valCol])
    })

    autoTable(doc, {
      //styles: { fillColor: [255, 0, 0] },
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.25,
      head: [['Flood Occurrence Statistics', '']],
      headStyles: {
        fillColor: [25, 182, 190]
      },
      theme: 'striped',
      startY: 165,
      columnStyles: { 1: { textColor: [25, 182, 190] } }, // Cells in first column centered and green
      body: tbody
    })

  }

  getFootNotesNum(txt) {
    return this.footNotesNum[txt]
  }

  getFloodHistory(doc: jsPDF) {

    let tbody = [];

    //limit events to not overflow
    this.historyDataSource.forEach((data: any, index: any) => {
      if (index <= 9) {
        tbody.push([data.date, data.classification])
      } else if (index == 10) {
        tbody.push(["...", "..."])
      } else {
        //not showing events over 10
      }

    })

    let customColorCell = function (data) {
      let txt = data.cell.text[0]
      if (txt == "Moderate") {
        doc.setTextColor('orange')
      } else if (txt == "High") {
        doc.setTextColor('red')
      } else if (txt == "Date (MM/YYYY)" || txt == "Confidence **") {
        doc.setTextColor("white")
      } else {
        doc.setTextColor("black")
      }
    };

    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    doc.text("Flood History Events", 120, 53)
    doc.setFont("helvetica", "normal")
    doc.setFontSize(10);
    doc.text("Total Observed Events From " + this.getEventDateRange() + ":", 120, 58)
    doc.setTextColor(25, 182, 190)
    doc.text(this.historyEvents.length.toString(), 186, 58)

    doc.setTextColor("black");
    doc.setFontSize(6);
    doc.setFont("helvetica", "italic")
    doc.text("Note: Events indicate the months in which surface water was observed at the specified",121,62)
    doc.text("point, with an unobstructed (cloudless) view. Flooding that occurred during a storm",121,65)
    doc.text("(with cloud cover), or that was not observed by the satellites, would not be recorded.",121,68);
    doc.setFont("helvetica", "normal")

    autoTable(doc, {
      head: [['Date (MM/YYYY)', 'Confidence **']],
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.15,
      headStyles: {
        fillColor: [25, 182, 190],
        textColor: [256, 256, 256]
      },
      //styles: { fillColor: [255, 0, 0] },
      margin: { left: 120 },
      theme: 'striped',
      startY: 71,
      //tableWidth:'auto',
      willDrawCell: customColorCell,

      columnStyles: {
        0: {
          cellWidth: 38,
        }, 1: {
          textColor: [25, 182, 190],
          cellWidth: 38,
        },
      }, // Cells in first column centered and green
      //margin: { top: 10 },
      body: tbody
      //[['Test1', 'Test2'],['Test11', 'Test22'],],
    })
  }


  getMapImage(doc: jsPDF) {
    var self = this;
    /**
     * Old way of pulling the image from the actual map, switched to getting a generated image from the api, keeping this in case
     */
    // this.map.once('rendercomplete', function () {
    // const mapCanvas = document.createElement('canvas');
    // mapCanvas.setAttribute('crossorigin', 'Anonymous');
    // const size = self.map.getSize();
    // mapCanvas.width = size[0];
    // mapCanvas.height = size[1];
    // const mapContext = mapCanvas.getContext('2d');
    // Array.prototype.forEach.call(
    //   self.map.getViewport().querySelectorAll('.ol-layer canvas, canvas.ol-layer'),
    //   function (canvas) {
    //     if (canvas.width > 0) {
    //       const opacity =
    //         canvas.parentNode.style.opacity || canvas.style.opacity;
    //       mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);

    //       const backgroundColor = canvas.parentNode.style.backgroundColor;
    //       if (backgroundColor) {
    //         mapContext.fillStyle = backgroundColor;
    //         mapContext.fillRect(0, 0, canvas.width, canvas.height);
    //       }

    //       let matrix;
    //       const transform = canvas.style.transform;
    //       if (transform) {
    //         // Get the transform parameters from the style's transform matrix
    //         matrix = transform
    //           .match(/^matrix\(([^\(]*)\)$/)[1]
    //           .split(',')
    //           .map(Number);
    //       } else {
    //         matrix = [
    //           parseFloat(canvas.style.width) / canvas.width,
    //           0,
    //           0,
    //           parseFloat(canvas.style.height) / canvas.height,
    //           0,
    //           0,
    //         ];
    //       }
    //       // Apply the transform to the export map context
    //       CanvasRenderingContext2D.prototype.setTransform.apply(
    //         mapContext,
    //         matrix
    //       );
    //       mapContext.drawImage(canvas, 0, 0);
    //     }
    //   }
    // );
    // mapContext.globalAlpha = 1;
    // var urlTest = mapCanvas.toDataURL()
    // //urlTest.setAttribute('crossorigin', 'anonymous');

    // // imgP.src = urlTest
    // // mapCanvas.toBlob(function(blob) {
    // // })

    //doc.addImage(urlTest, "data:image/png;base64", x, y, w, h);
    doc.addImage(this.floodOccImgStaticUrl, "data:image/png;base64", 5, 50, 110, 110, undefined, 'FAST');
    doc.rect(5, 50, 110, 110)
    //doc.addImage(this.colorbarUrl,"png",20,190,80,20)
    //   // link download attribute does not work on MS browsers
    //   navigator.msSaveBlob(mapCanvas.msToBlob(), 'map.png');
    // } else {
    //   const link = document.getElementById('image-download');
    //   link.href = mapCanvas.toDataURL();
    //   link.click();
    // }
    //});
    this.map.renderSync();




    //   //this.map.once('rendercomplete', function() {
    //     var target = this.map.getViewport();
    //     // options for html2canvas to filter out all the 'ol-control' elements
    //     var configOptions = {
    //         // ignoreElements: function(target) {
    //         //     return (target.className.indexOf('ol-control') !== -1); },
    //         //logging: false
    //     };
    //     var self = this;
    //     html2canvas(target, configOptions)
    //         .then(function(canvas) {
    //             canvas.toBlob(function(blob) {
    //               //let objectURL = URL.createObjectURL(blob);       
    //               // let image = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
    //               // //let image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    //               let urlBlob = URL.createObjectURL(blob)
    //               let image3 = self.sanitizer.bypassSecurityTrustResourceUrl(urlBlob);

    //               doc.addImage(urlBlob, "", 15, 90, 180, 180);
    //             //saveAs(blob, 'map.png');
    //             });
    //     });
    // //});
  }

  getFooter(doc: jsPDF) {
    doc.line(0, 250, 210, 250); // horizontal line    
    doc.setFont("helvetica", "normal");
    doc.setTextColor('black')
    doc.setFontSize(8);
    doc.text("*          Number of adjacent 30m grid cells to calculate statistics over. For example, a value of 2 will yield a final 150m x 150m region.", 10, 255);
    doc.text('**         A qualitative measure of the water classification confidence based on the satellite retrieval. Values can be: “High”, “Moderate” or “Low - Wetland”.', 10, 260);
    doc.text('             The latter indicates a low confidence for surface inundation and/or presence of a wetland where vegetation is impacting the signal.', 10, 265)
    doc.text("***       This score is the ratio of the number of water observations normalized by the number of clear sky observations.", 10, 270)
    doc.text("****      This score is the average ratio of the number of water observations normalized by the number of clear sky observations for the entire", 10, 275)
    doc.text("              selected region.", 10, 280)
    doc.text("*****     The count of satellite scenes containing the selected location with less than 20% cloud cover.", 10, 285)
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);

    doc.text("File Generated on: " + today.toLocaleDateString(), 170, 295)
  }


  //map functions
  createMap() {

    var control = new ScaleLine({
      units: 'metric',
      className: 'ol-scale-line'
    });

    //var mapCenter = olProj.fromLonLat([-74,40])
    this.map = new Map({
      target: 'simple_map',
      controls: defaultControls().extend([control]),

      layers: [
        new TileLayer({
          source: new XYZ({
            url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
            crossOrigin: 'Anonymous',
          }),
        }),
        new TileLayer({
          source: new XYZ({
            tilePixelRatio: 2,
            url:
              //'https://stamen-tiles.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}@2x.png'
              'http://services.arcgisonline.com/arcgis/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}',
              
          }),
        })
        // new TileLayer({
        //   source: new Stamen({
        //     layer: 'toner-lines',

        //   }),
        // }),

        // new TileLayer({
        //   source: new XYZ({
        //     tilePixelRatio: 2,
        //     //projection:"EPSG:4326",
        //     url:
        //       'https://stamen-tiles.a.ssl.fastly.net/toner-labels/{z}/{x}/{y}@2x.png',
        //     crossOrigin: 'Anonymous',
        //   }),
        // }),

      ],
      view: new View({
        projection: 'EPSG:4326',
        //projection: 'EPSG:3857',
        center: [-74.40707073356168, 40.38270665721783],
        //center: caliCenter,
        zoom: 6,
        maxZoom: 19,
      }),
    });

    var pixelRatio = 1
    this.map.pixelRatio_ = pixelRatio;
    return this.map;
  }


  // checkBufferCells(evt){
  //   console.log(evt);
  //   console.log(this.selectedBufferCell);
  //   console.log(this.selectedAdjCell);
  //   this.selectedBufferCell = evt.value
  //   if(this.selectedBufferCell >= this.selectedAdjCell){
  //     console.log("in if");
  //     let newVal = this.selectedAdjCell -1
  //     this.selectedBufferCell =  newVal;
  //     console.log(this.selectedBufferCell);
  //     setTimeout(() => {
  //       this.selectedBufferCell = newVal;
  //     })
  //   }
  // }
}



export interface HistoryEvent {
  date: string;
  classification: string;
}

export interface ResponseInterface {
  keyCol: string;
  valCol: string;
}