<div class="api-background">
    <div class="grid-container">
        <mat-grid-list cols="{{ ( cardLayout | async )?.columns }}" rowHeight="200px">

            <mat-grid-tile [colspan]="( cardLayout | async )?. table1!.cols"
                [rowspan]="( cardLayout | async )?.table1!.rows">
                <!-- <app-mini-selector></app-mini-selector> -->
                <div class="api-key-box">
                    <div class="container">
                        <div class="row">
                            <div class="api-key-header">
                                API Key Generator
                            </div>
                        </div>
                        <div class="api-key-inputs">
                            <div class="row">
                                <mat-label class="key-label">Key Name</mat-label>
                                <input class="key-input" matInput type="text" [(ngModel)]="this.auth.keyName">
                                <button class="input-btn" mat-raised-button color="primary"
                                    (click)="this.auth.createKey()"> Create Key</button>
                            </div>

                            <div class="row">
                                <mat-label class="key-label">Check if Valid</mat-label>
                                <input class="key-input" matInput type="text" [(ngModel)]="this.auth.keyCheck">
                                <button class="input-btn" mat-raised-button color="primary"
                                    (click)="this.auth.validateKey()"> Check Validity</button>
                            </div>
                        </div>

                    </div>
                </div>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="( cardLayout | async )?.table2!.cols"
                [rowspan]="( cardLayout | async )?.table2!.rows" *ngIf="this.userKeys">
                <!-- <div *ngFor = "let key of this.userKeys">
                    <ul>
                        <li>
                            {{key.name}}
                        </li>
                    </ul>
    
                    
                </div> -->
                <div *ngIf="this.userKeys.length == 0" class="api-key-box">
                    <div class="container">
                        <div class="row">
                            <div class="api-key-header">
                                Api Keys
                            </div>
                        </div>
                        <div class="row">
                            <div class="no-keys">
                                No Keys Created
                            </div>
                        </div>


                    </div>
                </div>
                <table *ngIf="this.userKeys.length > 0" mat-table [dataSource]="this.userKeys"
                    class="mat-elevation-z3 key-table">
                    <!-- Position Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Name</th>
                        <td mat-cell *matCellDef="let element">{{element.name}}</td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef class="delete-header">Delete</th>
                        <td mat-cell *matCellDef="let element" class="delete-column"
                            (click)="this.auth.deleteKey(element.name)">
                            <mat-icon class="mr">clear</mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

            </mat-grid-tile>

        </mat-grid-list>
    </div>
</div>