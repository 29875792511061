import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthPagesRoutingModule } from './auth-pages-routing.module';
import { LoginComponent } from './login/login.component';
import {firebase, firebaseui, FirebaseUIModule} from 'firebaseui-angular';
import {environment} from '../../environments/environment';
import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule, USE_EMULATOR as USE_AUTH_EMULATOR} from '@angular/fire/auth';
//import { HeaderComponent } from '../shared/header/header.component';

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [

    {
      requireDisplayName: false,
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      disableSignUp:{
        status:true,
        adminEmail:'info@hydronoslabs.com'
      }
    },

  ],
  tosUrl: 'www.hydronoslabs.com/terms',
  privacyPolicyUrl: 'www.hydronoslabs.com/privacy',
  credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
};
@NgModule({
  declarations: [
    LoginComponent,
    //HeaderComponent  
  ],
  imports: [
    CommonModule,
    AuthPagesRoutingModule,
    AngularFireModule,//.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    
  ],
  providers: [
    //{ provide: USE_AUTH_EMULATOR, useValue: !environment.authproduction ? ['localhost', 9099] : undefined },
  ],
})
export class AuthPagesModule { }
