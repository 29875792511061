<div class = "account-background">
    <div class = "account-container">
        
        <div class = "account-box" *ngIf = "!auth.userFirstName || !auth.userLastName || auth.updateUserFlag" >

            <span class = "account-info-header">Update Account</span>
            
            <mat-list class = "account-list">
                <mat-list-item>
                    <span class = "account-info-label">First Name:</span>
                    <input matInput [(ngModel)] = "this.auth.userFirstName">
                </mat-list-item>
                <mat-list-item>
                    <span class = "account-info-label">Last Name:</span>
                    <input matInput [(ngModel)] = "this.auth.userLastName">
                </mat-list-item>
                <mat-list-item>
                    <span class = "account-info-label">Organization:</span>
                    <input matInput [(ngModel)] = "this.auth.userOrganization">
                </mat-list-item>
            </mat-list>
            <button class = "account-button" mat-raised-button color="primary" (click) = "this.auth.updateUser()">
                Update User
            </button>
<!-- 
            Update Account:
            <mat-form-field appearance="fill">
                <mat-label>First Name</mat-label>
                <input matInput [(ngModel)] = "this.auth.userFirstName">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Last Name</mat-label>
                <input matInput [(ngModel)] = "this.auth.userLastName">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Organization</mat-label>
                <input matInput [(ngModel)] = "this.auth.userOrganization">
            </mat-form-field> -->

        </div>
        
        <div  class = "account-box" *ngIf = "auth.userFirstName && auth.userLastName && !auth.updateUserFlag" >
            <span class = "account-info-header">Account Info</span>
            
            <mat-list class = "account-list">
                <mat-list-item>
                    <span class = "account-info-label">First Name:</span>
                    <span class = "account-info-value">{{this.auth.userFirstName}}</span>
                </mat-list-item>
                <mat-list-item>
                    <span class = "account-info-label">Last Name:</span>
                    <span class = "account-info-value">{{this.auth.userLastName}}</span>
                </mat-list-item>
                <mat-list-item>
                    <span class = "account-info-label">Email:</span>
                    <span class = "account-info-value">{{this.auth.user.email}}</span>
                </mat-list-item>
                <mat-list-item>
                    <span class = "account-info-label">Organization:</span>
                    <span class = "account-info-value">{{this.auth.userOrganization}}</span>
                </mat-list-item>
            </mat-list>

            <button class = "account-button" mat-raised-button color="primary" (click) = "this.auth.updateUserFlag = true"> Update</button>
            
        </div>
    </div>

</div>
