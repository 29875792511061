import { Component, OnInit, AfterViewInit } from '@angular/core';
import 'ol/ol.css';
import { SimpleApiService } from 'src/app/services/simple-api.service';
import ImageLayer from 'ol/layer/Image';
import ImageStatic from 'ol/source/ImageStatic';
import Static from 'ol/source/ImageStatic';
import { fromLonLat, Projection, transform } from 'ol/proj';
import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import { Vector } from 'ol/source';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon'
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Circle from 'ol/style/Circle'
import { getArea } from 'ol/sphere';
import Draw, {
  createBox,
  createRegularPolygon,
} from 'ol/interaction/Draw';
import WKT from 'ol/format/WKT';


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit {

  constructor(public simpleApi: SimpleApiService) { }
  map: any;
  vectorSource;
  vectorLayer: any;

  marker;
  imageLayer: any;
  projection: any;
  pointDraw: any;
  rectDraw: any;
  drawSource = new VectorSource({ wrapX: false });
  drawVector = new VectorLayer({
    source: this.drawSource
  });
  ngAfterViewInit(): void {
    this.map = this.simpleApi.createMap();
    this.map.addLayer(this.drawVector);
    this.simpleApi.getUpdateMapImage().subscribe((val: any) => {
      if (!val) {
        //CLEAR MAP
        this.clearMap();
        console.log("clear map")
      } else {

        // var westx = -74.201857;
        // var southx = 39.7670;
        // var eastx =  -73.093555;
        // var northx = 39.928750;

        var west = this.simpleApi.floodOccImgResponse.result[0].llLat;
        var south = this.simpleApi.floodOccImgResponse.result[0].llLon;
        var east = this.simpleApi.floodOccImgResponse.result[0].urLat;
        var north = this.simpleApi.floodOccImgResponse.result[0].urLon;

        var extent = [west, south, east, north];
        //console.log(extent)

        this.projection = new Projection({
          code: 'image-overlay',
          //units: 'pixels',
          extent: extent,
        });
        this.imageLayer = new ImageLayer({
          source: new Static({
            url: this.simpleApi.floodOccImgUrl,
            projection: this.projection,
            imageExtent: extent,
          }),
          opacity: 0.6,
        });
        this.imageLayer.setZIndex(2);
        this.imageLayer.set('name', 'imageLayer')
        this.map.addLayer(this.imageLayer)
      }
    })

    this.simpleApi.getCenterMapImage().subscribe((val: any) => {
      this.clearMap();
      if (val) {
        this.map.getView().setCenter([this.simpleApi.inputLon, this.simpleApi.inputLat]);
        this.map.getView().setZoom(16);

        this.marker = new Feature({
          geometry: new Point([this.simpleApi.inputLon, this.simpleApi.inputLat])
        });

        this.marker.setStyle(new Style({
          fill: new Fill({
            color: 'rgba(138, 253, 116, 0.9)'
          }),
          stroke: new Stroke({
            color: 'black',
            width: 2
          }),
          image: new Circle({
            radius: 6,
            fill: new Fill({
              color: 'rgba(138, 253, 116, 0.9)'
            })
          })
        }));
        this.marker.setStyle(new Style({
          image: new Icon({
            anchor: [0.5, 0],
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            anchorOrigin: 'bottom-left',
            src: '../../assets/logos/marker.svg',
          })
        }));



        this.vectorSource = new VectorSource({
          features: [this.marker]
        });

        this.vectorLayer = new VectorLayer({
          source: this.vectorSource,
          zIndex: 5
        });
        this.map.addLayer(this.vectorLayer)

      }
    })

    this.simpleApi.getDrawStart().subscribe((val: any) => {
      if (val) {
        console.log(this.simpleApi.drawType);
        this.removeInteraction();
        if (this.simpleApi.drawType == "point") {
          this.addPointInteraction();
        } else {
          this.addRectInteraction();
        }
      } else {
        this.removeInteraction();
      }
    }
    )

  }

  clearMap() {
    //console.log(this.imageLayer)
    try {
      this.map.getLayers().forEach(layer => {
        if (layer && layer.get('name') === 'imageLayer') {
          this.map.removeLayer(layer);
        }
      });
      // if(this.imageLayer.getSource()){
      //   console.log(this.imageLayer.getSource())
      //   console.log("trying to clear image")
      //   //this.imageLayer.getSource().clear();
      // }
      if (this.vectorLayer.getSource().getFeatures()) {
        this.vectorLayer.getSource().clear();
      }
    } catch (err) {
      //console.log("catching no inital layer")
    }

    // var features = this.imageLayer.getSource().getFeatures();
    // features.forEach((feature) => {
    //     this.imageLayer.getSource().removeFeature(feature);
    // });
    // var features = this.vectorLayer.getSource().getFeatures();
    // features.forEach((feature) => {
    //     this.vectorLayer.getSource().removeFeature(feature);
    // });
  }


  addPointInteraction() {
    if (this.drawSource.getFeatures()) {
      this.drawSource.clear()
    }
    this.pointDraw = new Draw({
      source: this.drawSource,
      type: "Point",
    });
    var self = this;
    this.pointDraw.on('drawend', function (evt) {
      if (self.drawSource.getFeatures()) {
        self.drawSource.clear()
      }
      var feature = evt.feature;
      var coords = feature.getGeometry().getCoordinates();
      self.simpleApi.inputLon = coords[0].toFixed(5)
      self.simpleApi.inputLat = coords[1].toFixed(5);
      //this.removeInteraction();
    });
    this.map.addInteraction(this.pointDraw);

  }
  addRectInteraction() {
    if (this.drawSource.getFeatures()) {
      this.drawSource.clear()
    }
    this.rectDraw = new Draw({
      source: this.drawSource,
      type: "Circle",
      geometryFunction: createBox(),
    });

    var self = this;
    this.rectDraw.on('drawend', function (evt) {
      if (self.drawSource.getFeatures()) {
        self.drawSource.clear()
      }
      var feature = evt.feature;
      console.log(feature);
      console.log(evt);
      var coords = feature.getGeometry().getCoordinates();
      console.log(coords);
      self.setInputBoxCoords(coords)
      var geo = feature.getGeometry();
      console.log(geo)
      //this seems completely wrong, will need to try turf or maybe change projection input
      let area = getArea(geo);
      console.log(area);
      var format = new WKT();
      var wktR = format.writeGeometry(geo);
      console.log(wktR)
      self.simpleApi.wktShape = wktR;

    });
    this.map.addInteraction(this.rectDraw);

  }

  setInputBoxCoords(coords: any) {
    console.log(coords);
    this.simpleApi.minInputLon = coords[0][0][0].toFixed(5);
    this.simpleApi.minInputLat = coords[0][0][1].toFixed(5);
    this.simpleApi.maxInputLon = coords[0][1][0].toFixed(5);
    this.simpleApi.maxInputLat = coords[0][2][1].toFixed(5);
  }

  removeInteraction() {
    //may need to check if it exists first;
    this.map.removeInteraction(this.rectDraw);
    this.map.removeInteraction(this.pointDraw);
  }


}
