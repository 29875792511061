import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFireModule, FirebaseApp } from '@angular/fire'
import { FirebaseUISignInFailure, FirebaseUISignInSuccessWithAuthResult } from 'firebaseui-angular';
import { from, NEVER, Observable, Observer, of, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DialogService } from './dialog.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn: boolean;
  user = null;
  userLoggedInSubject = new Subject<Boolean>();
  keyName;
  keyCheck;
  userFirstName = null;
  userLastName = null;
  userOrganization = null;
  updateUserFlag = false;
  isUserSet = new Subject<Boolean>();
  apiAccess = false;
  simpleApiAccess = false;
  internalAccess = false;
  requestArr = [];
  apiKey = environment.hydronosKey;

  constructor(public afAuth: AngularFireAuth, private db: AngularFireDatabase, public af: FirebaseApp, private myRoute: Router, private http: HttpClient, public dialogService: DialogService) {
    //var database = af.database();

    afAuth.onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        this.checkApiRoute();
        this.checkSimpleApiRoute();
        this.checkInternalRoute();
        var uid = user.uid;
        
        this.isLoggedIn = true
        this.setUserLoggedInSubject(true)
        // ...
      } else {
        this.isLoggedIn = false
        this.user = null;
        this.myRoute.navigate(["/login"]);
      }
    });

  }
  getUserLoggedInSubject() {
    return this.userLoggedInSubject;
  }

  setUserLoggedInSubject(val: any) {
    this.userLoggedInSubject.next(val)
  }

  successCallback(signInSuccessData: FirebaseUISignInSuccessWithAuthResult) {
    //navigate to home page? 
    //if new user add to database
    if (signInSuccessData.authResult.additionalUserInfo.isNewUser) {
      //call create new user function
      this.createNewUser(signInSuccessData.authResult.user)
      //navigate to update info?
      this.myRoute.navigate(["/account"]);
    }else{
      this.myRoute.navigate([""]);
    }
    

  }

  createNewUser(user) {
    var database = this.af.database();
    database.ref('users/' + user.uid).set({
      group: "basic",
      email:user.email
    });
  }

  errorCallback(errorData: FirebaseUISignInFailure) {
    console.log(errorData)
  }

  uiShownCallback(data) {
    console.log(data);
  }

  logOut() {
    this.afAuth.signOut()
    this.setUserLoggedInSubject(false)
    this.myRoute.navigate(["/login"]);
  }

  // isLoggedIn(){

  // }
  setUser(user) {
    this.checkApiRoute();
    this.checkSimpleApiRoute();
    //this.checkInternalRoute();
    this.user = user;
    if(user){
      this.getAccountInfo()
    }
    
  }

  getAuthenticated(): Observable<firebase.default.User> {
    return this.afAuth.user;
  }

  createKey() {
    if (this.keyName) {
      //get current user uid
      //call api route with UID and key name
      //display api to user with one time message
      this.callCreateKey(this.keyName, this.user.uid).subscribe((data: any) => {
        //alert("Key Created : " + data.key)
        const errOptions = {
          title: 'Key Created',
          message: 'API Key: ' + data.key,
          errorMessage: '',
          confirmText: 'Close'
        };
        this.dialogService.open(errOptions);
      }, error => {
        console.log(error)
        alert(error)
      })

    } else {
      const errOptions = {
        title: 'Error',
        message: 'Please Enter a Key Name',
        errorMessage: '',
        confirmText: 'Close'
      };
      this.dialogService.open(errOptions);
    }
  }

  callCreateKey(keyName, uid) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    var params = new HttpParams().set('keyName', keyName).set('uid', uid);
    //var url = "http://api.hydronoslabs.com/apiKey"
    var url = environment.apiUrl + "/apikey"
    return this.http.get(url, { headers, params });
  }

  validateKey() {
    if (this.keyCheck) {
      //get current user uid
      //call api route with UID and key name
      //display api to user with one time message
      this.callValidateKey().subscribe((data: any) => {
        //alert("Key is valid")
        const errOptions = {
          title: 'Key is Valid',
          message: 'Key is valid',
          errorMessage: '',
          confirmText: 'Close'
        };
        this.dialogService.open(errOptions);
      }, error => {
        console.log(error)
        //alert("Key is not valid")
        const errOptions = {
          title: 'Error',
          message: 'API Key is not valid',
          errorMessage: '',
          confirmText: 'Close'
        };
        this.dialogService.open(errOptions);
      })

    } else {
      //alert("please enter a key name");
      const errOptions = {
        title: 'Error',
        message: 'Please Enter a Key Name',
        errorMessage: '',
        confirmText: 'Close'
      };
      this.dialogService.open(errOptions);
    }
  }

  callValidateKey() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    var params = new HttpParams().set('apiKey', this.keyCheck);
    //console.log(params)
    //var url = "http://api.hydronoslabs.com/apiKey/valid"
    var url = environment.apiUrl + "/apikey/valid"

    return this.http.get(url, { headers, params });
  }

  getUserKeys() {
    //var db = this.af.database();
    //var ref = db.ref('users/' + this.user.uid +'/keys')
    var itemsRef = this.db.list('users/' + this.user.uid + '/keys')
    var items = itemsRef.valueChanges();
    return items;
    //console.log(database)

  }

  getGroupKeys(){
    var itemsRef = this.db.list('groups/api/')

    var items = itemsRef.snapshotChanges();

    return items;
  }

  async deleteKey(keyName) {

    //delete key from the users doc
    const userKeyRef = this.af.database().ref('users/' + this.user.uid + '/keys')
    userKeyRef.orderByChild('name').equalTo(keyName).on('child_added', (snapshot) => {
      snapshot.ref.remove()
    });
    //delete key from the main keys doc (that matches uid only)
    const keysRef = this.af.database().ref('keys/')
    keysRef.orderByChild('uid').equalTo(this.user.uid).on('child_added', (snapshot) => {
      var entry = snapshot.val();
      if(entry.keyName == keyName){
        snapshot.ref.remove()
      }

    });

  }

  getAccountInfo(){

    var self = this;
   return this.af.database().ref('users/' + this.user.uid).on('value', function(snapshot) {
      //console.log(snapshot);
      const data = snapshot.val();// || null;
      if(data.firstName && data.lastName && data.organization){
        //console.log(data.firstName)
        self.userFirstName = data.firstName;
        self.userLastName = data.lastName;
        self.userOrganization = data.organization
        self.setIsUserSet(true);
      }
      else{
        self.updateUserFlag = true;
        self.setIsUserSet(false);
      }
      
      //self.setIsUserSet(true)
      //return data
    });
  }
  
  updateUser(){
    if(!this.userFirstName || !this.userLastName || !this.userOrganization){
      alert("Please enter all fields")
    }else{
      //update user info
      this.af.database().ref('users/' + this.user.uid).set({
        firstName: this.userFirstName,
        lastName: this.userLastName,
        organization:this.userOrganization,
        email:this.user.email
      });
      this.updateUserFlag = false;
    }
  }
  userDetailsValid(){
    if(!this.userLastName || !this.userFirstName || !this.userOrganization){
      return false
    }else{
      return true
    }
  }

  getIsUserSet(){
    return this.isUserSet;
  }

  setIsUserSet(val:any){
    this.isUserSet.next(val)
  }

  //call this to make sure the user has account info stored.  Not using the authguard anymore
  checkAccountIsValid(){
    var self = this;
    this.af.database().ref('users/' + this.user.uid).on('value', function(snapshot) {
      //console.log(snapshot);
      const data = snapshot.val();// || null;
      if(data.firstName && data.lastName && data.organization){
        //valid, everyhing is fine
        self.userFirstName = data.firstName;
        self.userLastName = data.lastName;
        self.userOrganization = data.organization
      }
      else{
        //user is not set, change route
        self.updateUserFlag = true;
        alert("Please set your account info");
        self.myRoute.navigate(["/account"]);
      }
      
      //self.setIsUserSet(true)
      //return data
    });
  }

  homepage(){
    //window.location.href = "http://34.122.208.149/"
    window.location.href = environment.homepageUrl
    //window.location.href = "http://hydronoslabs.com"
  }


  checkApiRoute(){
    if(this.user){
      var self = this;
      this.af.database().ref('groups/api/' + this.user.uid).on('value', function(snapshot) {
        const data = snapshot.val();// || null;
        //console.log(data);
        if(data){
          //set apiAccess
          self.apiAccess = true;
          //return true
        }else{
          self.apiAccess =false
          //return false
        }
      });
    }


  }
  
  checkSimpleApiRoute(){
    if(this.user){
      var self = this;
      this.af.database().ref('groups/simpleapi/' + this.user.uid).on('value', function(snapshot) {

        const data = snapshot.val();// || null;
        if(data){
          //set apiAccess
          self.simpleApiAccess = true;
          //return true
        }else{
          self.simpleApiAccess =false
          //return false
        }
      });
    }


  }

  checkApiAccess(): Observable<any> {
    return this.afAuth.authState.pipe(
      mergeMap(authState => {
        this.setUser(authState);
        if (authState) {
          return from( this.af.database().ref('groups/api/'+ authState.uid).get());
        } else {
          return NEVER;
        }
      })
    );
  }

  checkSimpleApiAccess(): Observable<any> {
    return this.afAuth.authState.pipe(
      mergeMap(authState => {
        this.setUser(authState);
        if (authState) {
          return from( this.af.database().ref('groups/simpleapi/'+ authState.uid).get());
        } else {
          return NEVER;
        }
      })
    );
  }

  checkInternalRoute(){
    if(this.user){
      var self = this;
      this.af.database().ref('groups/internal/' + this.user.uid).on('value', function(snapshot) {
        const data = snapshot.val();// || null;
        if(data){
          //set apiAccess
          self.internalAccess = true;
          //self.getRequestInfo();
          //return true
        }else{
          self.internalAccess =false
          //return false
        }
      });
    }


  }

  checkInternalAccess(): Observable<any> {
    return this.afAuth.authState.pipe(
      mergeMap(authState => {
        this.setUser(authState);
        if (authState) {
          return from( this.af.database().ref('groups/internal/'+ authState.uid).get());
        } else {
          return NEVER;
        }
      })
    );
  }

  getRequestInfo(){
    var self = this;
    this.requestArr = [];
   this.af.database().ref('requests/').on('value', function(snapshot) {
      const data = snapshot.val();// || null;
      for (var key of Object.keys(data)) {
        // console.log(key + " -> " + data[key])
        let obj = { email: key, firstName:key, lastName:key, data:data[key]}
        self.requestArr.push(obj)
        // self.af.database().ref('users/' + key).on('value', function(snapshot2) {
        //   //console.log(snapshot);
        //   const data2 = snapshot2.val();// || null;
        //   console.log(data2)
        //   console.log(data[key]);
        //   console.log(key);

        //   let obj = { email: data2.email, firstName:data2.firstName, lastName:data2.lastName, data:data[key]}
        //   console.log(obj)
        //   self.requestArr.push(obj)
        //   //figure out if we want a table or chart?
        //   //add in feature to run logs
        // })

      }
      self.updateRequestNames();
      // data.forEach((row:any) => {
      //   console.log(row);
      //   console.log(row[0]);
      //   return this.af.database().ref('users/' + row[0]).on('value', function(snapshot2) {
      //     //console.log(snapshot);
      //     const data2 = snapshot.val();// || null;
      //     console.log(data2)
      //   })
        
      //  });
      // if(data.firstName && data.lastName && data.organization){
      //   //console.log(data.firstName)
      //   self.userFirstName = data.firstName;
      //   self.userLastName = data.lastName;
      //   self.userOrganization = data.organization
      //   self.setIsUserSet(true);
      // }
      // else{
      //   self.updateUserFlag = true;
      //   self.setIsUserSet(false);
      // }
      
      //self.setIsUserSet(true)
      //return data
    });

  }
 
  updateRequestNames(){
    var self = this;
    this.requestArr.forEach((row:any, index:any) => {
        self.af.database().ref('users/' + row.email).on('value', function(snapshot) {
          //console.log(snapshot);
          const data = snapshot.val();// || null;
          self.requestArr[index].email = data.email
          self.requestArr[index].firstName = data.firstName 
          self.requestArr[index].lastName = data.lastName
          //figure out if we want a table or chart?
          //add in feature to run logs
        })
    })

  }

  getLogStatus(){
    this.callLogStatus().subscribe((data: any) => {
      alert("Oldest file not logged -> " + data.file)
      
    }, error => {
      console.log(error)
    })
  }

  //call log status api and get when it was last updated
  callLogStatus(){
    var url = environment.apiUrl + "/logs/status"
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': this.apiKey
    });
    return this.http.get(url, { headers: headers});//, responseType: "blob" });
  }

  runLogs(){
    this.callLogs().subscribe((data: any) => {
      console.log(data);
      
    }, error => {
      console.log(error)
    })
  }

  //call log status api and get when it was last updated
  callLogs(){
    var url = environment.apiUrl + "/logs/run"
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': this.apiKey
    });
    return this.http.get(url, { headers: headers});//, responseType: "blob" });
  }
}
