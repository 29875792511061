<div class="header error-header">
    <span mat-dialog-title>{{ data.title }}</span>
</div>
<div mat-dialog-content class = "main-content-div">
    <p class="dialog-message">{{ data.message }}</p>
    <div *ngIf = "data.errorMessage">
       Error Mesage: 
       <span class = "error-message">
         {{ data.errorMessage }}
       </span> 
    </div>
</div>
<div mat-dialog-actions class = "close-div">
    <!-- <button class="btn btn-cancel" style="margin-right:10px;" (click)="cancel()">{{ data.cancelText }}</button> -->
    <button mat-raised-button color = "accent" class="btn close-btn" (click)="confirm()">{{ data.confirmText }}</button>
</div>