import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-api-keys',
  templateUrl: './api-keys.component.html',
  styleUrls: ['./api-keys.component.scss']
})
export class ApiKeysComponent implements OnInit {
  keyCheck;
  keyName;
 /** Based on the screen size, switch from standard to one column per row */
 cardLayout = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
  map(({ matches }) => {
    if (matches) {
      return {
        columns: 6,
        table1: { cols: 6, rows: 2 },
        table2: { cols: 6, rows: 2 },
      };
    }

    return {
      // 2 mini card layout
      // columns: 6,
      // miniSelector: { cols: 2, rows: 1 },
      // miniCard: { cols: 1, rows: 1 },
      // chart: { cols: 2, rows: 2 },
      // map: { cols: 4, rows: 4 },
      // wildfireCard: {cols: 2, rows:2}
      columns: 6,
      table1: { cols: 3, rows: 2 },
      table2: { cols: 3, rows: 2 },

    };
  })
);

constructor(private breakpointObserver: BreakpointObserver, public auth: AuthService) { }
resizeObservable$: Observable<Event>
resizeSubscription$: Subscription
userKeys;
displayedColumns: string[] = ['name', 'delete'];
ngOnInit() {
  this.resizeObservable$ = fromEvent(window, 'resize')
  this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
    //console.log('event: ', evt)
  })
 this.auth.getUserKeys().subscribe((data: any) => {
   //console.log(data)
   this.userKeys = data
   //console.log(this.userKeys)
 });
 this.auth.checkAccountIsValid();

  //console.log(this.userKeys)
}



}
