export const environment = {
    test:"dev",
    production: true,
    authproduction:true,
    firebaseConfig:{
      apiKey: "AIzaSyBeonhzoyFY5KsE5wCq3fMgcQICzYbVNTw",
      authDomain: "hydronos-interface-dev.firebaseapp.com",
      databaseURL: "https://hydronos-interface-dev-default-rtdb.firebaseio.com/",
      projectID: "hydronos-interface-dev"
    },
    apiDoc:"assets/dev-swagger.json",
    homepageUrl:"https://dev-home.hydronoslabs.com",
    apiUrl:"https://dev-api.hydronoslabs.com",
    interfaceUrl:"https://dev-interface.hydronoslabs.com",
    hydronosKey:"e4b5c981-1f17-4fc2-9d4d-37435eea0e08",
    rtKey:"5c6c9239-2ca7-482e-8572-e79277969849",
    rtUserId:"5p5CNpxkHOWPXrc1T8dnCkrhP4p2",
    version:"v1.4.dev"
  };
  