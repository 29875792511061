
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ErrorDialogComponent } from '../shared/error-dialog/error-dialog.component';
@Injectable()
@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog) { }
  dialogRef: MatDialogRef<ErrorDialogComponent>;
  
  public open(options: { title: any; message: any; errorMessage: any; confirmText: any; }) {
    this.dialogRef = this.dialog.open(ErrorDialogComponent, {    
         data: {
           title: options.title,
           message: options.message,
           errorMessage: options.errorMessage,
           confirmText: options.confirmText
         }
    });
  }
  public confirmed(): Observable<any> {
    
    return this.dialogRef.afterClosed().pipe(take(1), map(res => {
        return res;
      }
    ));
  }
}