<div class="api-background">
  <button (click)="this.auth.getLogStatus()">Get log status</button>
  <button (click)="this.auth.runLogs()">Run logs</button>
    <div class="grid-container top-pad">
       <div *ngIf = "auth.requestArr" class = "request-box">
        <mat-accordion>
            <mat-expansion-panel *ngFor="let user of auth.requestArr">
              <mat-expansion-panel-header>
                
                <mat-panel-title>
                    {{user.firstName}} {{user.lastName}}
                  </mat-panel-title>
                  <mat-panel-description>
                    {{user.email}}
                  </mat-panel-description>
              </mat-expansion-panel-header>

              <div *ngFor = "let date of user.data | keyvalue">
                {{date.key}}
                <div *ngFor = "let res of date.value | keyvalue">
                    Route: {{ getRoute(res.key) }} -- {{res.value.count}}
                </div>
            </div>
            </mat-expansion-panel>
          
          </mat-accordion>
            <!-- <div *ngFor = "let user of auth.requestArr">
                
                {{user.email}}
                {{user.firstName}}
                {{user.lastName}}
                {{user.data}}
                <div *ngFor = "let date of user.data | keyvalue">
                    <div *ngFor = "let res of date.value | keyvalue">
                        Route: {{res.key}} -- {{res.value.count}}
                    </div>
                </div>
            </div> -->
       </div>
    </div>
</div>