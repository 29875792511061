import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/internal/operators/map';
import { AuthService } from 'src/app/services/auth.service';
import { MenuItem } from './menu-item';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  user;
  loggedIn;
  
  
  menuItems: MenuItem[] = [
    // {
    //   label: 'Login',
    //   icon: 'login',
    //   showOnMobile: true,
    //   showOnTablet: true,
    //   showOnDesktop: true,
    //   showOnLogout: true,
    //   routerLink:"login"
    // },
    {
      label: 'Home',
      icon: 'home',
      showOnMobile: false,
      showOnTablet: true,
      showOnDesktop: true,
      showOnLogout: false,
      routerLink:"/"
    },
    {
      label: 'Account',
      icon: 'account_circle',
      showOnMobile: false,
      showOnTablet: true,
      showOnDesktop: true,
      showOnLogout: false,
      routerLink:"/account"
    },
    // {
    //   label: 'API',
    //   icon: 'code',
    //   showOnMobile: true,
    //   showOnTablet: true,
    //   showOnDesktop: true,
    //   showOnLogout: false,
    //   routerLink:"/api"
    // },
    // {
    //   label: 'Logout',
    //   icon: 'logout',
    //   showOnMobile: false,
    //   showOnTablet: false,
    //   showOnDesktop: false,
    //   showOnLogout: false,
    //   routerLink:""
    // },

  ];
  constructor(public auth: AuthService, public router: Router) {

  }

  ngOnInit(): void {
    this.auth.getUserLoggedInSubject().subscribe({
      next: data => {
        if (data) {
          this.loggedIn = true;
        } else {
          this.loggedIn = false;
        }
      }
    })

  }

}
