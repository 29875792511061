import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountModule } from './account/account.module';
import { ApiModule } from './api/api.module';
import { AuthPagesModule } from './auth-pages/auth-pages.module';
import { AuthGuard } from './auth.guard';
import { HomeModule } from './home/home.module';
const routes: Routes = [
  {
    path:'login',
    loadChildren: () => AuthPagesModule
  },
  {
    path:'',
    canActivate:[AuthGuard],
    loadChildren: () => HomeModule
  },
  {
    path:'account',
    canActivate:[AuthGuard],
    loadChildren: () => AccountModule
  },
  {
    path:'api',
    canActivate:[AuthGuard],
    loadChildren: () => ApiModule
  },
  {path: '**', 
  canActivate:[AuthGuard],
  loadChildren: () => HomeModule}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
