import { Component, ElementRef, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import SwaggerUI from 'swagger-ui'
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-api-doc',
  templateUrl: './api-doc.component.html',
  styleUrls: ['./api-doc.component.scss']
})
export class ApiDocComponent implements OnInit {

  constructor(private el: ElementRef, public auth: AuthService) { }

  ngOnInit(): void {

    const DisableTryItOutPlugin = function () {
      return {
        statePlugins: {
          spec: {
            wrapSelectors: {
              allowTryItOutFor: () => function (state, route) {
                if (route == "/flood/occurrence" || route == "/flood/history" || route == "/flood/generate_image" || route == "/flood/download_image") {
                  return true
                } else {
                  return false
                }
              }
            }
          }
        }
      }
    }


    const HideInfoUrlPartsPlugin = () => {
      return {
        wrapComponents: {
          InfoUrl: () => () => null
        }
      }
    }

    const ui = SwaggerUI({
      url: environment.apiDoc,
      domNode: this.el.nativeElement.querySelector('.swagger-container'),
      deepLinking: true,
      defaultModelsExpandDepth: -1,
      presets: [
        SwaggerUI.presets.apis
      ],
      plugins: [
        DisableTryItOutPlugin,
        HideInfoUrlPartsPlugin
      ],

    });
    this.auth.checkAccountIsValid();

  }

}
