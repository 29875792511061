import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {

  constructor(public auth: AuthService) { }

  ngOnInit(): void {
    console.log(this.auth.requestArr);
    this.auth.getRequestInfo();
  }

  getRoute(num:any){
    switch (num) {
      case "1":
        return "Flood Occurrence"
      case "2":
          return "Flood History"
      case "3":
        return "Occurrence Image"
      default:
        break;
    }
  }

}
