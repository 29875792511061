import { ChangeDetectionStrategy, Component, HostListener, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent{
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
                  // cancelText: string,
                  confirmText: string,
                  message: string,
                  errorMessage: string,
                  title: string
                }, private mdDialogRef: MatDialogRef<ErrorDialogComponent>) { }
  public cancel() {
    this.close(false);
  }
  public close(value:any) {
    this.mdDialogRef.close(value);
  }
  public confirm() {
    this.close(true);
  }
  @HostListener("keydown.esc") 
  public onEsc() {
    this.close(false);
  }
}