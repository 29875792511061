import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService,
    private myRoute: Router) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if(state.url == "/api/rt"){
        return this.auth.checkSimpleApiAccess().pipe(
          map(dbReturn => {
            //dbreturn if user has access to api
            //if not return, user is not logged in
            //this.auth.setUser(user);
            //double check user has account info?
              //check if 
              if (dbReturn) {
                if(dbReturn.val() || state.url !== '/api/rt'){
                  return true
                }else{
                  this.myRoute.navigate(["/"]);
                  return false;
                }
                //need to determine if user can access certain routes (api key route should be blocked except for a certain group)
                //return true;
              } else {
                this.myRoute.navigate(["/login"]);
                return false;
              }
            
          })
    
        )
      }else if(state.url == "/api/logs"){
        console.log("checking here?");
        return this.auth.checkInternalAccess().pipe(
          map(dbReturn => {
            //dbreturn if user has access to api
            //if not return, user is not logged in
            //this.auth.setUser(user);
            //double check user has account info?
              //check if 
              console.log(dbReturn);
              
              if (dbReturn) {
                if(dbReturn.val() || state.url !== '/api/logs'){
                  return true
                }else{
                  this.myRoute.navigate(["/"]);
                  return false;
                }
                //need to determine if user can access certain routes (api key route should be blocked except for a certain group)
                //return true;
              } else {
                this.myRoute.navigate(["/login"]);
                return false;
              }
            
          })
    
        )
      }else{
        return this.auth.checkApiAccess().pipe(
          map(dbReturn => {
            //dbreturn if user has access to api
            //if not return, user is not logged in
            //this.auth.setUser(user);
            //double check user has account info?
              //check if 
              if (dbReturn) {
                if(dbReturn.val() || state.url !== '/api/keys'){
                  return true
                }else{
                  this.myRoute.navigate(["/"]);
                  return false;
                }
                //need to determine if user can access certain routes (api key route should be blocked except for a certain group)
                //return true;
              } else {
                this.myRoute.navigate(["/login"]);
                return false;
              }
            
          })
    
        )
      }

  }

}
