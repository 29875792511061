<!-- <h1 mat-dialog-title class = "notes-header">FHIRS API and Visualization Platform:</h1> -->
<mat-dialog-content class="mat-typography">
    <h1 class = "notes-header">FHIRS API and Visualization Platform:</h1>

    <h2  class = "notes-subhead-1" >FHIRS API v1.3 and Platform v1.4 (September 2022) - Updates:  </h2>
    <ul>
        <li>Added in a bufferCell option to API calls, giving the ability to exclude certain cells from regional calculations.</li>
        <li>Visualization of bufferCell option added to Platform.</li>
        <li>Platform now removes cells with insufficient observations (nObs < 120) from regional calculations.</li>
        <li>Fix for mismatch in reprojection between occurrence and image calls.</li>
        <li>Cloud scalability improved:</li>
            <ul>
                <li>Response time reduced and stability increased for concurrent requests</li>
                <li>Fixed issue with data loss when scaling up nodes</li>
            </ul>
        <li>Data storage and retrieval improvements:</li>
            <ul>
                <li>Upgraded cloud storage drives for faster IO</li>
                <li>Improved data scalability across multiple clusters for concurrent requests</li>
                <li>Code improvements for in memory data retrieval, improving  response times</li>
            </ul>
    </ul>

    <h2  class = "notes-subhead-1" >FHIRS API v1.2, v1.2.1 and Platform v1.3, v1.3.1 (April 2022) - Updates: </h2>
    <ul>
        <li>Performance improvements for faster response times (time series and image creation).</li>
        <li>Reporting functionality (in the form of a PDF) has been included to generate static reports for further distribution of the results presented on the web interface.</li>
        <li>Flood History is now aggregated to months in which water was observed rather than the specific dates of each observation.</li>
        <li>The color scale has been updated for more differentiation of flood occurrence scores.</li>
        <li>Permanent water is now identified in visualizations, indicating areas such as rivers and lakes that do not contribute to flood occurrence summary results.</li>
        <li>A bug has been fixed in the reprojection of flood history results for map visualization. This does not affect score values.</li>
    </ul>

    
    <h2  class = "notes-subhead-1" >FHIRS API v1.1 and Platform v1.2 (March 2022) - Updates: </h2>
    <ul>
        <li>Performance improvements for faster response times (time series and image creation).</li>
        <li>API was adjusted to remove “low confidence” events from the returns for all calls.</li>
        <li>Minor adjustments to the Platform visualizations and added mobile friendly view.</li>
    </ul>

    <h2  class = "notes-subhead-1" >FHIRS API v1.0 and Platform v1.1 (December 2021) - Initial Release:</h2>
    <!-- <h3 class = "notes-subhead-2">Updates:</h3> -->
    <ul>
        <li>FHIRS API access is offered from the Hydronos Platform hosted on Google Cloud with load balancing and auto-scaling for API requests with 99.9% uptime.</li>
        <li>Basic query - consists of request for a single point latitude and longitude and returns:</li>
        <ul>
            <li>Frequency - The percentage of historical satellite observations where water was observed on the site.</li>
            <li>Number of Observations - Total number of clear sky satellite observations.</li>
            <li>Description of satellite sources available for the target site.</li>
        </ul>
        <li>Advanced query - define a region larger than a single point to return summary stats over that region. </li>
        <ul>
            <li>Stats include: Min, Max, Mean, Median and Standard deviation of the historical frequency. </li>
        </ul>
        <li>Additional queries -  </li>
        <ul>
            <li>The full history of observed flood events for a given location can be requested. This includes the date of water occurrence, as well as a confidence metric.</li>
            <li>Images of the aggregated flood occurrence scores can be returned and displayed in other applications (see below).</li>
        </ul>        
        <li>A front-end visualization interface was also customized for use by reThought (the “Platform”) -</li>
        <ul>
            <li>Allows for all of the API calls to be made with minimal user input.</li>
            <li>Users can define location of interest, adjacent cells, and time period.</li>
            <li>Visualizes flood occurrence score and flood history time series results.</li>
        </ul>
        <li>Interactive API documentation is also included on the platform page, allowing the users to design and test API calls, as well as create and delete API keys.</li>
        <li>A PDF walkthrough document is provided to explain all of the functionality described above.</li>
    </ul>

    <h2  class = "notes-subhead-1" >Q3-4 2022 - Future Release(s):</h2>
    <ul>
        <li>API - Request occurrence scores by inputting a defined building perimeter. Scores will be computed for a specified distance from the perimeter of this building  and will “net out” the interior of the building.</li>
        <li>API - Access to other data for requested areas, including elevation profile, historical precipitation and local streamflow observations.</li>
        <li>API/Platform - Integration of calls  and report generation into the rTip workflow as another step for underwriters.</li>
        <li>Platform - Implement visualization map layers for: </li>
        <ul>
            <li>Number of total satellite observations</li>
            <li>Number of satellite observations with water</li>
            <li>Confidence level overlay</li>
            <li>Permanent water identification</li>
            <li>Elevation, Slope, Height Above Nearest Drainage, FEMA Flood Zones</li>
        </ul>
        <li>Platform - Implement interactive selection of building perimeters or other geometries for score retrieval</li>
    </ul>

    <h1 class = "notes-header">FHIRS Data:</h1>

    <h2  class = "notes-subhead-1" > v1.2.1 (September 2022) - Updates:</h2>
    <ul>
        <li>Fix for missing data in some regions of the CONUS domain. Some gaps still remain, due to lack of satellite coverage.</li>
    </ul>

    <h2  class = "notes-subhead-1" > v1.2 (April 2022) - Updates:</h2>
    <ul>
        <li>Permanent water is now identified from Landsat and other static datasets for exclusion from the calculation of regional flood occurrence scores.</li>
    </ul>
    
        
    <h2  class = "notes-subhead-1" >v1.1 (January 2021) - Updates: </h2>
    <ul>
        <li>Fix for reprojection issues of the source data that lead to rounding errors in score calculation for a given requested coordinate.</li>
    </ul>

    
    <h2  class = "notes-subhead-1" >v1.0 (December 2021) - Initial Release:</h2>
    <ul>
        <li>Satellite data (including all available Landsat scenes) processed for Flood Occurrence scores at a 30m spatial resolution over two historical time periods (1984-2000) and (2000-2019). </li>
        <li>All historical Landsat scenes with cloud cover less than 20% and other ancillary data are collected and available for time series retrieval and event specific visualization.</li>
    </ul>
    

    <h2  class = "notes-subhead-1" >Q3-4 2022 - Future Release(s):</h2>
    <ul>
        <li>Extension of observations and scores for 2020-2021.</li>
        <li>Additional time period discretization for computing historical occurrence scores relative to building construction date.</li>
        <li>Enhanced downscaled Passive Microwave flood occurrence observations.</li>
        <li>Integration of real-time SAR based observations from Sentinel satellites to provide for rapid assessment of recent flooding conditions.</li>
        <li>Notification of current NWS designations of counties with flood warning or watch when querying a specific location.</li>
        <li>Where available, provide historical discharge observations from USGS as well as current observed flood stages. Enable notifications for queried points that are within a watershed that is expected to experience flooding conditions. </li>
    </ul>
    
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>