<div class="grid-container homepage-container">
    <div class="product-container">
        <mat-grid-list cols="{{ ( cardLayout | async )?.columns }}" rowHeight="100px">
            <!-- <mat-grid-tile [colspan]="( cardLayout | async )?. header!.cols"
                [rowspan]="( cardLayout | async )?.header!.rows">
                <div class="welcome-text">Welcome to the Hydronos-Labs platform page</div>
            </mat-grid-tile> -->

            <mat-grid-tile [colspan]="( cardLayout | async )?. product!.cols"
                [rowspan]="( cardLayout | async )?.product!.rows">
                <!-- <app-mini-selector></app-mini-selector> -->
                <div class="product-box">
                    <div class="product-label">Hydronos Labs API</div>
                    <img class="box-screenshot" src="/assets/screenshots/api.PNG">
                    <button class="product-button" mat-raised-button color="primary"
                        (click)="this.navigate('api')">Open</button>
                </div>

            </mat-grid-tile>
            <mat-grid-tile [colspan]="( cardLayout | async )?. product!.cols"
                [rowspan]="( cardLayout | async )?.product!.rows">
                <!-- <app-mini-selector></app-mini-selector> -->
                <div class="product-box">
                    <div class="product-label">Flood History Platform</div>
                    <img class="box-screenshot-flood" src="/assets/screenshots/flood.jpg">
                    <button class="product-button" mat-raised-button color="primary">Coming Soon</button>
                </div>

            </mat-grid-tile>
            <mat-grid-tile [colspan]="( cardLayout | async )?. product!.cols"
                [rowspan]="( cardLayout | async )?.product!.rows">
                <!-- <app-mini-selector></app-mini-selector> -->
                <div class="product-box">
                    <div class="product-label">KWG Crop Impact Interface</div>
                    <img class="box-screenshot" src="/assets/screenshots/kwgscreen.jpg">
                    <button class="product-button" mat-raised-button color="primary"
                        (click)="this.navigate('kwg')">Open</button>
                </div>

            </mat-grid-tile>

            <mat-grid-tile [colspan]="( cardLayout | async )?. product!.cols"
                [rowspan]="( cardLayout | async )?.product!.rows">
                <!-- <app-mini-selector></app-mini-selector> -->
                <div class="product-box">
                    <div class="product-label">KnowWhereGraph</div>
                    <img class="box-screenshot-kwg" src="/assets/logos/kwg.png">
                    <button class="product-button" mat-raised-button color="primary"
                        (click)="this.navigate('kwg-home')">Open</button>
                </div>

            </mat-grid-tile>
            <mat-grid-tile [colspan]="( cardLayout | async )?. filler!.cols"
            [rowspan]="( cardLayout | async )?.filler!.rows">
            <!-- <app-mini-selector></app-mini-selector> -->


        </mat-grid-tile>
            <mat-grid-tile [colspan]="( cardLayout | async )?. product!.cols"
                [rowspan]="( cardLayout | async )?.product!.rows">
                <!-- <app-mini-selector></app-mini-selector> -->
                <div class="product-box">
                    <div class="product-label">Advanced Agriculture & Drought Intelligence</div>
                    <img class="box-screenshot" src="/assets/screenshots/haadi.jpg">
                    <button class="product-button" mat-raised-button color="primary"
                        >Coming Soon</button>
                </div>

            </mat-grid-tile>
        </mat-grid-list>
    </div>

</div>